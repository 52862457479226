<template>
<div>
    <div v-if="auth">
        <div class="row">
        	<div class="col-md-offset-3 col-md-6">
        		<div v-if="questionGps" class="m-b-50">
	            	<h5><b>Do you have GPS/GPX?</b></h5>
	            	<button class="btn btn-outline-secondary m-t-20" @click="noGps(true)">NO</button>
	            	<button class="btn btn-outline-secondary m-t-20" @click="noGps(false)">YES</button>
            	</div>
            	<div v-if="questionLatLng" class="m-b-50">
	            	<h5><b>OK but do you have trailhead co-ordinates?</b></h5>
	            	<button class="btn btn-outline-secondary m-t-20" @click="noLatLng">NO LAT/LNG</button>
            	</div>
        	</div>
        </div>

        <div class="row m-t-30 m-b-30" v-if="haveGps">
		    <div class="col-md-offset-3 col-md-6">
		        <input type="file" id="file" ref="file" class="form-control" @change="handleFileUpload()">
		    </div>

		    <div v-if="errorGps">
				<p>Not a valid file type!</p>
		    </div>

        </div>

 		<div class="row m-t-20 m-b-30" v-show="showMap">
            <div class="col-md-12" id="gps-map"></div>

	    	<div  v-if="gpsFile && !errorGps && !model.id">
		    	<p class="text-center m-t-20 m-l-30"> Does the GPX file look alright? Fix and re-upload if not.</p>
		    	<div>
					<button class="btn btn-outline-secondary m-t-10 m-l-20" @click="sendIt">Save GPS!</button>
				</div>
			</div>
	    </div>

		<div v-if="submitOK">
			<h5 class="text-center">Success!</h5>
			<div v-if="this.action == 'addGps'">
				<p class="text-center"> Thanks, click close now.</p>
			</div>
			<div v-else>
				<p class="text-center"> Thanks, now click next below to add any photos</p>
			</div>
		</div>

        <div class="row m-t-30" v-if="haveLatLng">
        	<div class="col-md-offset-3 col-md-6">
	            <input type="text" pattern="^-?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$" class="form-control" v-model="model.lat" placeholder="latitude (dd.dddd)">
        	</div>
        </div>

        <div class="row m-t-10 m-b-30" v-if="haveLatLng">
        	<div class="col-md-offset-3 col-md-6">
	            <input type="text" pattern="^-?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[1-7][0-9])(?:(?:\.[0-9]{1,6})?))$" class="form-control" v-model="model.lng" placeholder="longitude (dd.dddd)">
        	</div>
        </div>

        <div  v-if="this.errors" class="row m-t-10 m-b-30">
        	<div class="col-md-offset-3 col-md-9" style="color:red;">
	            Please fix missing field or errors, {{ this.errors }}
        	</div>
        </div>
    </div>
    
    <div v-if="!auth">
    	<div class="col-md-offset-3 col-md-9" style="color:red;">
	            Please sign in to be able to upload GPS (.gpx)
        </div>
    </div>

</div>  
</template>

<script>
	import {LMap, LTileLayer, LMarker } from 'vue2-leaflet';

	//import { Icon } from 'leaflet';
	//delete Icon.Default.prototype._getIconUrl;
	//Icon.Default.mergeOptions({
	//});

	import 'leaflet-gpx';

    export default {
    	components: {
	        LMap,
	        LTileLayer,
	        LMarker,
	    },
        name: 'step-gps',
        props: {
             finalModel: {
             	default: null,
             },
             auth: {
			    default: false,
			    type: Boolean
			 },
			 action: {
			 	default: 'wizardAddGps', // vs. addGps (during showTrail)
			    type: String
			 },
			 id: {
			 	default: null, // provide ID only when not in trail add wizard 
			 }
        },
        data() {
            return {
            	questionGps: true,
                haveGps: false,
                errorGps: false,
                questionLatLng: false,
                haveLatLng: false,
                model: {
                    gps: '',
                    lat: '',
                    lng: '',
                    id: ''
                },
                showMap: false,
                mapInit: false,
                gpsFile: '',
                gpxtext: '',
                trk: '',
			    zoom:13,
			    center: [0, 0],
 				url:'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
	     		attribution:'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
			    errors: '',
			    submitOK: false
            }
        },
        methods: {

        	validate() { 
        		var valid = true;
        		console.log("validate GPS step: " + this.action);

				this.$emit('on-validate', valid, this.model); // move to next step in wizard
				return true;
        	},
            sendIt() { // and submit to server
            	console.log("trail / gps going to send it");
                var valid = true;
                var self = this;

                if (this.gpsFile && this.errorGps) {
                	this.errors = " There was an error uploading your GPS file, please re-try or skip GPS upload."
                	return false;
                } else {
                	this.submit().then((result) => {
						self.model.id = result;
						console.log("trail add model ID is: " + self.model.id);

						// reset for new/replacement upload?
						this.questionGps = false;
						this.haveLatLng = false;
                		this.haveGps = false;
                		this.errorGps = false;
                		this.showMap = false;
					});
					return true;
                }

                return false;
            },
	        handleFileUpload() { // places on map in browser (not sent to server)
	        	this.errors = '';
	        	this.errorGps = false;
	        	this.showMap = false;
	        	var self = this;
	        	this.model.id = '';
			    this.gpsFile = this.$refs.file.files[0];
			    let ext = (this.$refs.file.files[0].name).split('.').pop().toLowerCase();

			    // if GPX format then read the data in the browser and show map (before user saves)
			    if (ext === 'gpx') {
				    var reader = new FileReader();
				    document.getElementById('gps-map').innerHTML = "<div class='map' id='map' ref='map'>" +
      		    		"<l-map :zoom='zoom' ref='trail-map' :center='center'></l-map></div>";

				    reader.onload = function(e) {
					    self.gpxtext = reader.result;
					    var map = L.map('map'); //var map = self.$refs.map.mapObject;

						L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
						    attribution: 'Map data &copy; <a href="http://www.osm.org">OpenStreetMap</a>'
						}).addTo(map);

						self.showMap = true;
												
						new L.GPX(self.gpxtext, {async: true, polyline_options: { color: 'red' }}).on('loaded', function(e) {
						    map.fitBounds(e.target.getBounds());
						}).addTo(map);
					}

					reader.readAsText(this.$refs.file.files[0]);

				} else if (ext !== 'gdb' && ext !== 'kml') {
					this.errorGps = true;
				}
			},
			async submit(){
				var self = this;
	            let formData = new FormData();
	            self.submitOK = false;
	            //this.model.id = ''; // set on successful send

	            let url = '/trails/add-trail';
	            if (this.action == 'wizardAddGps') { // new trail creation w. gps
	            	let trail = { ...this.finalModel, ...this.model };
	            	formData.set('trail', JSON.stringify(trail));

	            	if (this.model.id) { // could occur with back and forth
	            		formData.set('id',this.id);
	            	}
	            } else { // an update (GPS)
	            	if (!this.id) {
	            		this.errors = "no trail ID was provided, please report this error";
	            		this.errorGps = true; 
	            		console.log('GPS addition error, no trail ID for add');
	            	} else {
		            	formData.set('id',this.id); // gps addition existing trail
		            	url = '/trails/add-gps';
	            	}
	            }

	            if (this.gpsFile && !this.errorGps) {
	            	formData.append('gps-file', this.gpsFile, this.gpsFile.name);
	            }

	            // send with or without GPS since user may have skipped it
	        	await axios.post( url, formData,
	                { headers: 
	                	{ 'Content-Type': 'multipart/form-data' } 
	                }
	            ).then(response => {
	            	console.log('SUCCESS gps/trail step completed!! ' + response.data.id);
                    self.model.id = response.data.id; 
                    self.submitOK = true;
                })
		        .catch(error => {
                    if (error.response !== null) {
                        if (error.response.data){
                            self.errors = error.response.data.errors;
                            console.log("GPS call FAILURE: " + JSON.stringify(error.response.data)); 
                            return error.response.data;
                        }
                    } else {
                        console.log("unknown gps step error: " + error.response); 
                    }
                });

	            return self.model.id;
		    },
		    noGps(nogps) {
		    	this.questionGps = false;
		    	this.haveGps = false;

		    	if (nogps) {
			    	this.submit().then((result) => {
						  this.model.id = result;
						  this.questionLatLng =  false; // done now
						  console.log("async result model NO GPS is: " + this.model.id);
					});
		    	} else {
		    		this.haveGps = true;
		    	}

				return true;

		    	/*if (nogps) {
		    		this.haveGps = false;
		    		this.questionLatLng = true;
			    } */
		    },
		    noLatLng() {
		    	// not in place at this time - future
		    	console.log("user sas no lat/lng, sending trail data we have:"); 
		    	this.submit().then((result) => {
					  this.model.id = result;
					  this.questionLatLng =  false; // done now
					  console.log("async result model NO GPS or LAT/LNG ID is: " + this.model.id);
				});

				return true;
		    },
		    skip() {
		    	if (this.wizardAddGps) {
		    		Event.fire('skip');
		    	}
		    },
		},
        mounted() {
        	//console.log("step gps mounted");
        	this.submitOK = false;
        	this.gpsFile = false;
        	this.questionGps = true;
        },
          destroyed() {
		    //console.log('Component has been destroyed!');
		}
    }

</script>

<style>
	#map {
		height: 350px;
	}
	.form-control {
		padding: 0.275rem 0.75rem;
	}
</style>