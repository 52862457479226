<template>
<div>
	<!-- Video Add Modal -->
	<div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
		    <div class="modal-content">

			    <div class="modal-header" style="background-color: #000; color: orange;">
			        <h5 class="modal-title" id="videoModalLabel">Add Youtube Video</h5>
			        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			            <span aria-hidden="true">&times;</span>
			        </button>
			    </div>

				<div class="modal-body">
				    <div id="videoUp">
						<h4 v-if="!uploaded" class="text-center m-t-20 m-b-30 m-r-30">{{ msg }}</h4>
					    <div v-if="auth && !uploaded" class="text-center m-l-20 m-t-20">
				        	<p class="m-l-20 text-center">Please add Youtube share link eg https://youtu.be/FGaBkdiyzYs</p>
				        	<input id="video" type="text" class="form-control" v-model="video">
					       	<button class="btn btn-outline-secondary m-t-10" @click="upload">Save Video Link!</button>
				      	</div>  

				    	<div v-if="uploaded">
							<p class="m-t-20"> Thanks, your video was added, and will be reviewed. Please click close now and refresh page to review. </p>
				    	</div>

				    	<div v-if="!auth">
							<p class="m-t-20"> You must be signed in to add a photo, please close and login. </p>
				    	</div>

				    	<div  v-if="errors" class="text-center m-l-20 m-t-10">
					    	<div style="color:red;">
					            Failed, please fix or report error: {{ errors }}
					    	</div>
			    		</div>


				    	<button type="button" class="btn btn-secondary m-t-10" @click="closeVideo" data-dismiss="modal">Close</button>

				    </div>
				</div>
			</div>
		</div>
	</div>

</div>
</template>

<script>    
    import prettyJSON from "../utilities/prettyJson.js";

    export default {
    	name: 'video-box',
        props: {
			id: {
				default: null,
			},
			auth: {
			    default: 0
			 },
			admin: {
			    default: 0
			},
        },
        data() {
            return {
        		msg: "Add Video",
			    video: null,
			    uploaded: false,
			    errors: '',
        	}
        },
        methods: {
   			closeVideo() {
        		msg: "Add Video",
        		this.uploaded = false; // success
        		this.errors = '';
        		this.video = null;
        	},
			upload() {
				let formData = new FormData();
				var self = this;
				var uri = '/trails/add-video/';
				this.uploaded = false; // server upload - start
				this.errors = '';

	            formData.set('id', this.id);
	            formData.set('youtube', this.video);

	            axios.post( uri,
	                formData,
	            ).then(response => {
                    self.uploaded = true; 
                    console.log("upload response:" + JSON.stringify(response.data));
                }).catch(error => {
                    if (JSON.stringify(error) !== '{}' && error.response !== null) {
                    	var errMsg = 'error uploading to server, possibly youtube URL is incorrect';
                        if (error.response.data){
                            errMsg = error.response.data;
                            console.log("Video upload failure: " + JSON.stringify(error.response.data)); 
                        }
                        self.errors = errMsg;
                    } 
                });
			},
		},
		mounted() {
		}
    }    
</script>
<style>
 .modal-footer {
 	  background-color: white;
 }
.feature-link {
	z-index: 1000001;
	color: white;
	opacity: .6;
	height: .8rem;
	padding-top:3px;
}
 </style>

