<template>
    
    <div>
        <div class="row justify-content-md-center m-t-20">
            <h5><b>About the Trail</b></h5>
        </div>

        <div v-if="this.errors" style="color:red;">
        	Please fix missing field or errors with the following {{ this.errors }}
        </div>

        <div id="trail-details">
            <div class="container" style="padding: 0 50px 20px 0">

                <div class="row m-t-20 justify-content-center align-items-center">

                    <div class="col-md-5">
                    	<div class="panel-body">
                    		<label for="distance">distance total (km)</label>
                            <input id="distance" type="text"  class="form-control" v-model="model.distance">
                        </div>
                    </div>

                    <div class="col-md-1"></div>

                    <div class="col-md-6">
                    	<label for="elevation">elevation gain (m)</label>
                        <div class="panel-body">
                            <input id="elevation" type="text" class="form-control" v-model="model.elevation">
                        </div>
                    </div>
                </div>

                <div class="row m-t-20 justify-content-center align-items-center">
			        <div class="col-md-5">
			        	<label for="prov">rating (# stars)</label>
						<select id="rating" class="form-control" v-model="model.rating">
						  <option v-for="r in rates" v-bind:value="r">
						     {{ r }}
						  </option>
						</select>
			        </div>

                    <div class="col-md-1"></div>

                    <div class="col-md-6">
                    	<label for="type">type</label>
						<select id="type" class="form-control" v-model="model.type">
						  <option v-for="ty in types" v-bind:value="ty">
						     {{ ty }}
						  </option>
						</select>
                    </div>
                </div>

                <div class="row m-t-20 justify-content-center align-items-center">
                    <div class="col-md-5">
                        <div class="panel-body">
                        	<label for="time">time (hours)</label>
                            <input id="time" type="text"  class="form-control" v-model="model.time">
                        </div>
                    </div>

                    <div class="col-md-1"></div>

                    <div class="col-md-6">
                    	<label for="network">Park or Trail Network Name</label>
						<input id="network" type="text" class="form-control" v-model="model.network">
                    </div>
                </div>

                <div class="row m-t-12 justify-content-center align-items-center">
                	<div class="col-md-5">
                		<label for="activities">Activities (select all)</label>
						<select id="activities" class="form-control" v-model="model.activities" multiple="true" >
						  <option v-for="act in activities" v-bind:value="act">
						     {{ act }}
						  </option>
						</select>
					</div>

					<div class="col-md-1"></div>

					<div class="col-md-6">
                		<label for="seasons">Seasons (select all)</label>
						<select id="seasons" class="form-control" v-model="model.seasons" multiple="true" >
						  <option v-for="s in seasons" v-bind:value="s">
						     {{ s }}
						  </option>
						</select>
					</div>


                </div>

                <div class="row m-t-20 justify-content-center align-items-center">
                    <div class="col-md-12">
                        <div class="panel-body">
                        	<label for="description">Description (fully describe along with highights and cautions)</label>
                            <textarea id="description" rows="7" class="form-control" v-model="model.description"></textarea>
                        </div>
                    </div>
                </div>

                <div class="row m-t-20 justify-content-center align-items-center">
                    <div class="col-md-12">
                        <div class="panel-body">
                        	<label for="directions">Directions (fully describe)</label>
                            <textarea id="directions" rows="3" class="form-control" v-model="model.directions"></textarea>
                        </div>
                    </div>
                </div>

            </div>   
            
            <div v-if="this.errors" style="color:red;">
        		Please fix missing field or errors with the following {{ this.errors }}
        	</div>  
        </div>
    
    </div>  

</template>

<script>
    import prettyJSON from "../../utilities/prettyJson.js";
    export default {
        name: 'step-about',
        data() {
            return {
                model: {
                    activities: [],
                    seasons: [],
                    description: '',
                    directions: '',
                    distance: '',
                    elevation : '',
                    level: '',
                    network: '',
                    time: '',
                    days: '',
                    type: 'out-and-back',
                    rating: '',
                    trip_date: '',
                },
                types: ['loop', 'out-and-back', 'point-to-point', 'multi-loops'],
                rates: ['1', '2', '3', '4', '5'],
                errors: '',
                activities: ['hike', 'scramble', 'climb', 'mtn-bike', 'road-bike', 'fat-bike', 'ski-xc', 'ski-bc', 'dh-ski', 'snowshoe', 'trail-run', 'sea-kayak', 'sup', 'skate', 'kite-surf', 'river-canoe', 'swim', 'snowboard', 'yoga'],
                seasons: ['summer', 'winter', 'spring', 'fall'],

            }
        },
        methods: {
            validate() {
	              var valid = true;

	              if (!this.model.description) {
	                  valid = false; // error
	                  this.errors += ' / description ';
	              } else {
	              	this.model.description = this.model.description.replace(/<\/?[^>]+(>|$)/g, "");
	              }

	              if (!this.model.distance || !this.validNum( this.model.distance )) {
	                  valid = false; // error
	                  this.errors += ' / distance ';
	              }

	              if (!this.model.elevation || !this.validNum( this.model.elevation )) {
	                  valid = false; // error
	                  this.errors += ' / elevation ';
	              }

	              if (!this.model.time || !this.validNum( this.model.time )) {
	                  valid = false; // error
	                  this.errors += ' / time ';
	              }

	              if (!this.model.rating || !this.validNum( this.model.rating )) {
	                  valid = false; // error
	                  this.errors += ' / rating ';
	              }

	              if (!this.model.seasons) {
	                  valid = false; // error
	                  this.errors += ' / seasons ';
	              }	else {
					  var lngth = this.model.seasons.length;
					  for (var i = 0; i < lngth; i++) {
					      this.model.seasons[i] = this.model.seasons[i].replace(/<\/?[^>]+(>|$)/g, "");
					  }
	              }

	              if (!this.model.activities) {
	                  valid = false; // error
	                  this.errors += ' / activities ';
	              } else {
					  var lngth = this.model.activities.length;
					  for (var i = 0; i < lngth; i++) {
					      this.model.activities[i] = this.model.activities[i].replace(/<\/?[^>]+(>|$)/g, "");
					  }
	              }

	              if (!this.model.directions) {
	                  valid = false; // error
	                  this.errors += ' / directions ';
	              } else {
	              	  this.model.directions = this.model.directions.replace(/<\/?[^>]+(>|$)/g, "");
	              }

	              //console.log("step gps - valid: " + valid);

	              if (!valid) {
	              	console.log("input: " + JSON.stringify(this.model));
	              }

	              this.$emit('on-validate', valid, this.model); // see @on-validate method in parent
	              return valid;
            },
            validNum ( num ) {
            	var reg = /^\d+$/;
            	return reg.test( num );
            }
        },
        mounted() {
        	console.log("step about - mounted:");
        }
    }

</script>