var rtest = {

	validPlace ( value ) { 
		var reg = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
		return reg.test( value );

	},

  validTrailName ( value ) { 
    var reg = /^[a-zA-Z0-9-.' ]+$/; // alpha num with dashes, dots, spaces
    return reg.test( value );

  },

	validNum ( num ) {
		var reg = /^\d+$/;
		return reg.test( num );
	}
}

export default rtest;
