<script>    
    //import prettyJSON from "../utilities/prettyJson.js";
    import ReviewBox from './review.vue';
    import PhotoBox from './photoBox.vue';

    export default {
    	name: 'user-view',
    	components: {
			PhotoBox,
			ReviewBox
        },
        props: [
        	'auth',
			'uid',
			'type',
			''
        ],
        data() {
            return {
            	error: '',
            	result: '',
        	}
        },
        methods: {
   			clear() {
   				this.error = '';
   				this.result = '';
   			},
   		},
		mounted() {
		 	//console.log( "inline mounted ");
		},
    }    
</script>>
