var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMap
    ? _c(
        "div",
        {
          staticClass: "widest",
          staticStyle: { "background-color": "#bddab1" }
        },
        [
          _c("div", { staticClass: "widest", attrs: { id: "gps-t-map" } }),
          _vm._v(" "),
          _vm.errors
            ? _c("div", { attrs: { id: "error-msg" } }, [
                _vm._v("\n \t\t\t" + _vm._s(this.errors) + "\n \t\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showControl
            ? _c("div", { attrs: { id: "control" } }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.topoOn,
                        expression: "!topoOn"
                      }
                    ],
                    staticClass: "btn btn-secondary btn-sm",
                    on: { click: _vm.topo }
                  },
                  [_vm._v("Change to TOPO map  (click ONCE, wait ...)")]
                ),
                _vm._v(" "),
                _vm.expandedMap
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-sm",
                        on: { click: _vm.shrinkMap }
                      },
                      [_vm._v(" SHRINK ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-sm",
                        on: { click: _vm.expandMap }
                      },
                      [_vm._v(" EXPAND ")]
                    )
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }