var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "photoUp" } }, [
    !_vm.hasImage
      ? _c("h4", { staticClass: "text-center m-t-20 m-b-30 m-r-30" }, [
          _vm._v(_vm._s(_vm.msg))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center m-l-20 m-t-20" },
      [
        _c(
          "image-uploader",
          {
            attrs: {
              debug: 1,
              maxWidth: 1500,
              quality: 0.8,
              preview: true,
              className: ["fileinput", { "fileinput--loaded": _vm.hasImage }],
              capture: "environment",
              doNotResize: "gif",
              autoRotate: true,
              outputFormat: "file"
            },
            on: { input: _vm.setImage }
          },
          [
            _c(
              "label",
              {
                staticClass: "ml-2",
                attrs: { slot: "upload-label", for: "fileInput" },
                slot: "upload-label"
              },
              [
                _c("i", { staticClass: "fas fa-camera" }),
                _vm._v(" "),
                _c("span", { staticClass: "upload-caption" }, [
                  _vm._v(_vm._s(_vm.hasImage ? "Replace" : "Upload"))
                ])
              ]
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _vm.hasImage && !_vm.uploaded
        ? _c(
            "div",
            {
              staticClass: "row justify-content-md-center m-t-40 m-b-30 m-r-40"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary m-t-20",
                  on: { click: _vm.upload }
                },
                [_vm._v("Save this Photo!")]
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", [
      _vm.uploaded
        ? _c(
            "div",
            {
              staticClass: "row justify-content-md-center m-t-40 m-b-30 m-r-40"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary m-t-20",
                  on: { click: _vm.another }
                },
                [_vm._v("Click to replace, or NEXT if done")]
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.errors
      ? _c("div", { staticClass: "text-center m-l-20 m-t-20" }, [
          _c("div", { staticStyle: { color: "red" } }, [
            _vm._v(
              "\n            Please fix missing field or errors with the following: " +
                _vm._s(this.errors) +
                "\n    \t"
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }