var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm.errors
      ? _c("div", { staticStyle: { color: "red" } }, [
          _vm._v(
            "\n        \tPlease fix missing field or errors with the following " +
              _vm._s(this.errors) +
              "\n        "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "trail-details" } }, [
      _c(
        "div",
        { staticClass: "container", staticStyle: { padding: "0 50px 20px 0" } },
        [
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.newsletter,
                    expression: "model.newsletter"
                  }
                ],
                attrs: { type: "checkbox", id: "newsletter" },
                domProps: {
                  checked: Array.isArray(_vm.model.newsletter)
                    ? _vm._i(_vm.model.newsletter, null) > -1
                    : _vm.model.newsletter
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.model.newsletter,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.model, "newsletter", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.model,
                            "newsletter",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.model, "newsletter", $$c)
                    }
                  }
                }
              }),
              _c("span", { staticClass: "form-text" }, [
                _vm._v(" Receive Our Newsletter (future)? ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.contactme,
                    expression: "model.contactme"
                  }
                ],
                attrs: { type: "checkbox", id: "contactme" },
                domProps: {
                  checked: Array.isArray(_vm.model.contactme)
                    ? _vm._i(_vm.model.contactme, null) > -1
                    : _vm.model.contactme
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.model.contactme,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.model, "contactme", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.model,
                            "contactme",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.model, "contactme", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form-text" }, [
                _vm._v(
                  " Let other users contact you (email addresses never shared)"
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { attrs: { for: "gender_contactme" } }, [
                _vm._v("Limit Who can contact you by gender?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.gender_contactme,
                      expression: "model.gender_contactme"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "gender_contactme" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "gender_contactme",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.genders, function(g) {
                  return _c("option", { domProps: { value: g } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " + _vm._s(g) + "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { attrs: { for: "age_contactme" } }, [
                _vm._v("Limit age range for contact?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.age_contactme,
                      expression: "model.age_contactme"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "age_contactme" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "age_contactme",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.ageRange, function(a) {
                  return _c("option", { domProps: { value: a } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " + _vm._s(a) + "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.followme,
                    expression: "model.followme"
                  }
                ],
                attrs: { type: "checkbox", id: "followme" },
                domProps: {
                  checked: Array.isArray(_vm.model.followme)
                    ? _vm._i(_vm.model.followme, null) > -1
                    : _vm.model.followme
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.model.followme,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.model, "followme", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.model,
                            "followme",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.model, "followme", $$c)
                    }
                  }
                }
              }),
              _c("span", { staticClass: "form-text" }, [
                _vm._v(" Let other users follow you (see what you add or like)")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { attrs: { for: "gender_followme" } }, [
                _vm._v("Limit Who can follow you by gender?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.gender_followme,
                      expression: "model.gender_followme"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "gender_followme" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "gender_followme",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.genders, function(g) {
                  return _c(
                    "option",
                    {
                      domProps: {
                        value: g,
                        selected: _vm.model.gender_followme == g ? true : false
                      }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t     " + _vm._s(g) + "\n\t\t\t\t\t\t  "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { attrs: { for: "age_followme" } }, [
                _vm._v("Limit age range for following you?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.age_followme,
                      expression: "model.age_followme"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "age_followme" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "age_followme",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.ageRange, function(a) {
                  return _c("option", { domProps: { value: a } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " + _vm._s(a) + "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _vm.uploaded
            ? _c("div", { staticClass: "row m-t-20" }, [_vm._m(1)])
            : _vm._e(),
          _vm._v(" "),
          !_vm.uploaded
            ? _c("div", { staticClass: "row m-t-20" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary m-t-10",
                      on: { click: _vm.upload }
                    },
                    [_vm._v("Buzz Your Profile (SUBMIT to Profile Now)")]
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.errors
        ? _c("div", { staticStyle: { color: "red" } }, [
            _vm._v(
              "\n        \t\tPlease fix missing field or errors with the following " +
                _vm._s(this.errors) +
                "\n        \t"
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-md-center m-t-20" }, [
      _c("h5", [
        _c("b", [
          _vm._v(
            "Build Buzz by getting updates from other users and destinations"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h3", [_vm._v("Updated!")]),
      _vm._v(" "),
      _c("p", [_vm._v("( now click finish ==> below )")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }