<script>    
    //import prettyJSON from "../utilities/prettyJson.js";
    import StepGps from './trail/StepGps.vue';
    import PhotoBox from './photoBox.vue';
    import ReviewBox from './review.vue';
    import ShowGps from './showGps.vue';
    import DownloadGps from './downloadGps.vue';
    import nearbyTrails from './nearbyTrails.vue';
    
    export default {
    	name: 'trail-view',
        components: {
			StepGps,
			PhotoBox,
			ReviewBox,
			ShowGps,
			DownloadGps,
			nearbyTrails
        },
        props: [
        	'auth',
			'uid',
			'tid',
        ],
        data() {
            return {
            	want: false,
            	done: false,
            	error: '',
            	result: '',
            	gpsClick: false,
            	bigMap: false,
            	nearbyClick: false,
        	}
        },
        methods: {
        	wantIt() {
   				if (this.auth) {
	   				let url ="/users/wishlist/";
	   				this.send(url, this.uid, this.tid, 'want-to-do', null);
   				} else {
   					this.error = "you must sign in to use this feature";

   				}
        	},
   			doneIt() {
   				if (this.auth) {
	   				let url ="/users/wishlist/";
	   				this.send(url, this.uid, this.tid, 'done', null);
   				} else {
   					this.error = "you must sign in to use this feature";
   				}
   			},
   			handleTabChange(tabIndex, newTab, oldTab) {  
   				if (newTab.title == "Download GPS") {
   					this.gpsClick = true;
   				} 
   			},
   			handleMainTabsChange(tabIndex, newTab, oldTab) {  
   				if (newTab.title == "Nearby") {
   					this.nearbyClick = true;
   					neewTab.auth = oldTab.auth;
   				} 
   			},
   			resizeMap (direction) { 
   				if (direction == "up") {
					this.bigMap = true;
   				} else {
					this.bigMap = false;
				}
			},
   			send(url, uid, tid, action, data) {
   				this.error = "";
				var self = this;
	            let formData = new FormData();

	            if (uid) {
					formData.set('uid', uid);
				}
				if (tid) {
					formData.set('tid', tid);
				}
				if (action) {
					formData.set('action', action);  // done / want-to-do / do-again / ..
				}
	            if (data) {
	            	formData.set('data', JSON.stringify(data));
	            }

		        axios.post( url, formData
		            ).then(response => {
		            	console.log('SUCCESS send on: ' + url);
	                    self.result = "Success!";
	                })
			        .catch(error => {
	                    if (error.response !== null) {
	                        if (error.response.data){
	                        	if (error.response.data.errors) {
	                        		self.error = error.response.data.errors;
	                        	} else {
	                        		self.error = "unfortunately this failed, please report ";
	                        	}
	                            console.log(error.response.data); 
	                        }
	                    } else {
	                        console.log(error.response.data); 
	                    }
	                });
			},
   			clear() {
   				this.error = '';
   				this.result = '';
   			},
   		},
		mounted() {
			this.error = '';
			this.result = '';
			this.bigMap = false;
		},
    }    
</script>
