
require('./bootstrap');

window.Vue = require('vue');
require('./utilities/event');

import VueFormWizard from "vue-form-wizard";
import ImageUploader from 'vue-image-upload-resize';
import VueLazyLoad from 'vue-lazyload';
import VueTabs from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';

Vue.use(ImageUploader);
Vue.use(VueFormWizard);
Vue.use(VueLazyLoad);
Vue.use(VueTabs);

Vue.component('trail-view', require('./components/trailView.vue').default);
Vue.component('show-map', require('./components/map/showMap.vue').default);
Vue.component('user-view', require('./components/userView.vue').default);
Vue.component('trail-add-wizard', require('./components/trailAddWizard.vue').default);
Vue.component('user-add-wizard', require('./components/userAddWizard.vue').default);
Vue.component('star-rank', require('./components/stars.vue').default);
Vue.component('video-box', require('./components/videoBox.vue').default);

import 'leaflet/dist/leaflet.css';

const app = new Vue({
    el: '#app',
    validations: '',
});
