var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "photoUp" } }, [
    !_vm.hasImage
      ? _c("h4", { staticClass: "text-center m-t-20 m-b-30 m-r-30" }, [
          _vm._v(_vm._s(_vm.msg))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.auth
      ? _c(
          "div",
          { staticClass: "text-center m-l-20 m-t-20" },
          [
            _c(
              "image-uploader",
              {
                attrs: {
                  debug: 1,
                  maxWidth: 1500,
                  quality: 0.8,
                  preview: true,
                  className: [
                    "fileinput",
                    { "fileinput--loaded": _vm.hasImage }
                  ],
                  capture: false,
                  doNotResize: "gif",
                  autoRotate: true,
                  maxSize: 0.7,
                  outputFormat: "file"
                },
                on: { input: _vm.setImage, onUpload: _vm.startSpinner }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "ml-2",
                    attrs: { slot: "upload-label", for: "fileInput" },
                    slot: "upload-label"
                  },
                  [
                    _c("i", { staticClass: "fas fa-camera" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "upload-caption" }, [
                      _vm._v(_vm._s(_vm.hasImage ? "Replace" : "Upload"))
                    ])
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _vm.hasImage && !_vm.uploaded
        ? _c(
            "div",
            {
              staticClass: "row justify-content-md-center m-t-40 m-b-30 m-r-40"
            },
            [
              _c("p", { staticClass: "m-l-20 text-center" }, [
                _vm._v("Would you like to add a title (max 255 chars)")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.caption,
                    expression: "caption"
                  }
                ],
                staticClass: "form-control m-l-20",
                attrs: { id: "p-desc", type: "text" },
                domProps: { value: _vm.caption },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.caption = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary m-t-20",
                  on: { click: _vm.upload }
                },
                [_vm._v("Save this Photo Now!")]
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", [
      _vm.uploaded
        ? _c(
            "div",
            {
              staticClass: "row justify-content-md-center m-t-40 m-b-30 m-r-40"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary m-t-20",
                  on: { click: _vm.another }
                },
                [_vm._v("Upload Another Pic?")]
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.errors
      ? _c("div", { staticClass: "text-center m-l-20 m-t-20" }, [
          _c("div", { staticStyle: { color: "red" } }, [
            _vm._v(
              "\n            Please fix missing field or errors with the following: " +
                _vm._s(this.errors) +
                "\n    \t"
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c(
        "div",
        {
          staticClass: "spinner-border text-center m-5",
          class: { invisible: _vm.isHidden },
          attrs: { role: "status", id: "spinner" }
        },
        [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }