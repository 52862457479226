var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "photoModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "photoModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { attrs: { id: "photoUp" } }, [
                  !_vm.hasImage
                    ? _c(
                        "h4",
                        { staticClass: "text-center m-t-20 m-b-30 m-r-30" },
                        [_vm._v(_vm._s(_vm.msg))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.auth
                    ? _c(
                        "div",
                        { staticClass: "text-center m-l-20 m-t-20" },
                        [
                          _c(
                            "image-uploader",
                            {
                              attrs: {
                                debug: 1,
                                maxWidth: 1600,
                                quality: 0.9,
                                preview: true,
                                className: [
                                  "fileinput",
                                  { "fileinput--loaded": _vm.hasImage }
                                ],
                                capture: false,
                                doNotResize: "gif",
                                autoRotate: false,
                                maxSize: 0.9,
                                outputFormat: "file"
                              },
                              on: { input: _vm.setImage }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    slot: "upload-label",
                                    for: "fileInput"
                                  },
                                  slot: "upload-label"
                                },
                                [
                                  _c("i", { staticClass: "fas fa-camera" }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "upload-caption" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.hasImage ? "Replace" : "Upload"
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasImage && !_vm.uploaded
                    ? _c(
                        "span",
                        { staticClass: "row justify-content-md-center m-r-10" },
                        [
                          _c("p", { staticClass: "m-l-20 text-center" }, [
                            _vm._v(
                              "Would you like to add a title (max 255 chars)"
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.caption,
                                expression: "caption"
                              }
                            ],
                            staticClass: "form-control m-l-20",
                            attrs: { id: "p-desc", type: "text" },
                            domProps: { value: _vm.caption },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.caption = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-secondary m-t-10",
                              on: { click: _vm.upload }
                            },
                            [_vm._v("Save Photo!")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploaded
                    ? _c(
                        "span",
                        { staticClass: "row justify-content-md-center m-r-20" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-secondary m-t-10",
                              on: { click: _vm.another }
                            },
                            [_vm._v("Add Another?")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploaded
                    ? _c("div", [
                        _c("p", { staticClass: "m-t-20" }, [
                          _vm._v(
                            " Thanks, your photo was sent, and will be reviewed. Please click close if finished, or, add another. "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.error
                    ? _c("div", [
                        _c("p", { staticClass: "m-t-20" }, [
                          _vm._v(
                            " Oops, an error occurred, please let us know about this! "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.auth
                    ? _c("div", [
                        _c("p", { staticClass: "m-t-20" }, [
                          _vm._v(
                            " You must be signed in to add a photo, please close and login "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors
                    ? _c("div", { staticClass: "text-center m-l-20 m-t-10" }, [
                        _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t            Failed, please fix or report error: " +
                              _vm._s(_vm.errors) +
                              "\n\t\t\t\t\t    \t"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "spinner-border text-center m-1",
                      class: { invisible: _vm.isHidden },
                      attrs: { role: "status", id: "spinner" }
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Loading...")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary m-t-10",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.closePhoto }
                    },
                    [_vm._v("Close")]
                  )
                ])
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    Array.isArray(_vm.photos)
      ? _c(
          "div",
          [
            _c(
              "ul",
              { staticClass: "photoThumbs" },
              _vm._l(_vm.images, function(image, index) {
                return _c("li", { staticClass: "thumb-li" }, [
                  !image.thumb || image.thumb == "null"
                    ? _c("div", { class: "photo-thumb" })
                    : _c("div", {
                        class: "photo-thumb linkable",
                        style: { backgroundImage: "url(" + image.thumb + ")" },
                        on: {
                          click: function($event) {
                            return _vm.openGallery(index)
                          }
                        }
                      }),
                  _vm._v(" "),
                  _vm.what == "user" || _vm.what == "nearby"
                    ? _c("div", { staticClass: "mb-2 mt-1" }, [
                        _c("a", { attrs: { href: image.hrf } }, [
                          _c("span", { staticClass: "small-gray-link" }, [
                            _vm._v(
                              _vm._s(
                                image.caption
                                  .split("near")
                                  .pop()
                                  .replace(/<\/?[^>]+(>|$)/g, "")
                                  .substring(0, 17)
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.what == "user" &&
                        _vm.profile &&
                        _vm.userbox == "want"
                          ? _c("span", { staticClass: "pl-3" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/profile/" +
                                      _vm.id +
                                      "/want-to-do/delete/" +
                                      image.id
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "flaticon-trash iaction wishlist fa-sm"
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.what == "user" &&
                        _vm.profile &&
                        _vm.userbox == "done"
                          ? _c("span", { staticClass: "pl-3" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/profile/" +
                                      _vm.id +
                                      "/done/delete/" +
                                      image.id
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "flaticon-trash iaction wishlist fa-sm"
                                  })
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.admin && _vm.what != "user" && _vm.what != "nearby"
                    ? _c("div", { staticClass: "feature-link" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/image/trail/feature/" +
                                _vm.id +
                                image.src.substr(image.src.lastIndexOf("/"))
                            }
                          },
                          [_vm._v("feature | ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/image/trail/delete/" +
                                _vm.id +
                                image.src.substr(image.src.lastIndexOf("/"))
                            }
                          },
                          [_vm._v(" delete")]
                        )
                      ])
                    : _vm._e()
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("LightBox", {
              ref: "lightbox",
              attrs: {
                images: _vm.photos,
                "show-thumbs": false,
                "show-caption": true,
                "show-light-box": false
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal-header",
        staticStyle: { "background-color": "#000", color: "orange" }
      },
      [
        _c(
          "h5",
          { staticClass: "modal-title", attrs: { id: "photoModalLabel" } },
          [_vm._v("Add Photo")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close"
            }
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }