<template>
    
    <div class="widest" v-if="showMap" style="background-color:#bddab1;">
		<!-- show Gps -->
 		<div class="widest" id="gps-t-map"> 

 		</div>
 		<div id="error-msg" v-if="errors">
 			{{ this.errors }}
 		</div>

 		<div id="control" v-if="showControl">
			<button v-show="!topoOn" class="btn btn-secondary btn-sm" v-on:click="topo">Change to TOPO map &nbsp;(click ONCE, wait ...)</button>
			<button v-if="expandedMap" class="btn btn-secondary btn-sm" v-on:click="shrinkMap"> SHRINK </button>
			<button v-else class="btn btn-secondary btn-sm" v-on:click="expandMap"> EXPAND </button>
		</div>
    </div> 


</template>

<script>
	import L from 'leaflet';
	import {LMap, LTileLayer, LMarker } from 'vue2-leaflet';
	import 'leaflet-gpx';

    export default {
    	components: {
	        LMap,
	        LTileLayer,
	        LMarker,
	    },
        name: 'show-gps',
        props: {
            tid : {
				default: null,
			},
            cntry: {
				default: 'Canada',
			},
            prov: {
				default: 'BC',
			},
			gfile: {
				default: '',
			},
        },
        data() {
            return {
                gdata: '',
                zoom: 13,
                showMap: true,
                showControl: false,
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			    center: [0, 0],
			    errors: '',
			    fetched: false,
			    map: '',
			    expandedMap: false,
			    topoOn: false,
            }
        },
        methods: {
	        showGps(){

	        	document.getElementById('gps-t-map').innerHTML = "<div class='map' id='map' ref='map'>" +
      		    	"<l-map :zoom='zoom' ref='trail-map' :center='center'></l-map></div>";

      		    var map = L.map('map'); 
      		    this.map = map;

      		    if (!this.topo) {
	      		    L.tileLayer(this.url, {
							  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					}).addTo(map);
      			} else {
      				L.tileLayer(this.url, {
						attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
					}).addTo(this.map);
      			}

				new L.GPX(this.gdata, {async: true, 
					marker_options: {
					    startIconUrl: '/images/pin-icon-start.png',
					    endIconUrl: '/images/pin-icon-end.png',
					},
					gpx_options: { parseElements: ['track'] }, 
					polyline_options: { color: 'red' }
				}).on('loaded', function(e) {
				    map.fitBounds(e.target.getBounds());
				    if (map.getZoom() > 15) {
				    	map.setZoom(14);
				    }
				}).on('error', function(e) {
  					console.log('Error loading file: ' + e.err);
				}).addTo(map);

				this.showControl = true;

				//console.log('now added GPS data to map: ' + this.gdata.substring(1, 100));
			},
			topo() {
				this.topoOn = true;
				//this.map.invalidateSize();
				this.url = 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png';
				L.tileLayer(this.url, {
						attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
				        maxZoom: 16,
				}).addTo(this.map);
			},
		    expandMap() {
				this.zoom = 16;
				this.expandedMap = true;
				this.$emit('resize-map', 'up');

				this.showGps();
				document.getElementById("map").style.height = "550px";

			},
			shrinkMap() {
				this.zoom = 13;
				this.expandedMap = false;
				this.$emit('resize-map', 'down');

				this.showGps();
				document.getElementById("map").style.height = "350px";
			},
			osm() {
				this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
				L.tileLayer(this.url, {
						  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				}).addTo(this.map);
			},
		    fetchGps(){
				var self = this;
				this.fetched = false; 
				let formData = new FormData();

				// document.getElementById('gps-t-map').innerHTML = ''; // empty it out

	            formData.set('tid', this.tid);
	            formData.set('cntry',this.cntry);
	            formData.set('prov', this.prov);
	            formData.set('gfile', this.gfile);

		        axios.post( '/trails/fetch-gps', formData,
		            ).then(response => {
		            	self.fetched = true;
		            	self.showMap = true;
		            	if (response.data) {
		            	    self.gdata = response.data;
		            	    self.showGps();
		            	}
		            	// console.log('SUCCESS gps fetch: ' + self.gdata.substring(1, 100));
	                })
			        .catch(error => {
			        	this.fetched = false;
			        	self.gdata = '';
			        	self.showMap = false;
	                    if (error !== null) {
	                           console.log("GPS call FAILURE: " + JSON.stringify(error)); 
	                    }
	                });

	            return this.fetched;
		    },
		},
        mounted() {
        	this.expandedMap = false;
        	if (this.tid && this.gfile) {
    			this.fetchGps(); // 'showGps' (other value is wizardAddGps see stepGps)
    		}
        },
        destroyed() {
		    console.log('Component has been destroyed!');
		}
    }

</script>

<style>
	#map {
		height: 350px;
	}
	.form-control {
		padding: 0.275rem 0.75rem;
	}
</style>