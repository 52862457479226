var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    this.errors
      ? _c("div", { staticStyle: { color: "red" } }, [
          _vm._v(
            "\n        \tPlease fix missing field or errors with the following " +
              _vm._s(this.errors) +
              "\n        "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "trail-details" } }, [
      _c(
        "div",
        { staticClass: "container", staticStyle: { padding: "0 50px 20px 0" } },
        [
          _c(
            "div",
            {
              staticClass:
                "row m-t-20 justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "col-md-5" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("label", { attrs: { for: "distance" } }, [
                    _vm._v("distance total (km)")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.distance,
                        expression: "model.distance"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "distance", type: "text" },
                    domProps: { value: _vm.model.distance },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "distance", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("label", { attrs: { for: "elevation" } }, [
                  _vm._v("elevation gain (m)")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "panel-body" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.elevation,
                        expression: "model.elevation"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "elevation", type: "text" },
                    domProps: { value: _vm.model.elevation },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "elevation", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row m-t-20 justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "col-md-5" }, [
                _c("label", { attrs: { for: "prov" } }, [
                  _vm._v("rating (# stars)")
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.rating,
                        expression: "model.rating"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "rating" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.model,
                          "rating",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.rates, function(r) {
                    return _c("option", { domProps: { value: r } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t     " + _vm._s(r) + "\n\t\t\t\t\t\t  "
                      )
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("label", { attrs: { for: "type" } }, [_vm._v("type")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.type,
                        expression: "model.type"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "type" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.model,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.types, function(ty) {
                    return _c("option", { domProps: { value: ty } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t     " + _vm._s(ty) + "\n\t\t\t\t\t\t  "
                      )
                    ])
                  }),
                  0
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row m-t-20 justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "col-md-5" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("label", { attrs: { for: "time" } }, [
                    _vm._v("time (hours)")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.time,
                        expression: "model.time"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "time", type: "text" },
                    domProps: { value: _vm.model.time },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "time", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("label", { attrs: { for: "network" } }, [
                  _vm._v("Park or Trail Network Name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.network,
                      expression: "model.network"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "network", type: "text" },
                  domProps: { value: _vm.model.network },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.model, "network", $event.target.value)
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row m-t-12 justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "col-md-5" }, [
                _c("label", { attrs: { for: "activities" } }, [
                  _vm._v("Activities (select all)")
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.activities,
                        expression: "model.activities"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "activities", multiple: "true" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.model,
                          "activities",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.activities, function(act) {
                    return _c("option", { domProps: { value: act } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t     " + _vm._s(act) + "\n\t\t\t\t\t\t  "
                      )
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("label", { attrs: { for: "seasons" } }, [
                  _vm._v("Seasons (select all)")
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.seasons,
                        expression: "model.seasons"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "seasons", multiple: "true" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.model,
                          "seasons",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.seasons, function(s) {
                    return _c("option", { domProps: { value: s } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t     " + _vm._s(s) + "\n\t\t\t\t\t\t  "
                      )
                    ])
                  }),
                  0
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row m-t-20 justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("label", { attrs: { for: "description" } }, [
                    _vm._v(
                      "Description (fully describe along with highights and cautions)"
                    )
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.description,
                        expression: "model.description"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "description", rows: "7" },
                    domProps: { value: _vm.model.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "description", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row m-t-20 justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("label", { attrs: { for: "directions" } }, [
                    _vm._v("Directions (fully describe)")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.directions,
                        expression: "model.directions"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "directions", rows: "3" },
                    domProps: { value: _vm.model.directions },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "directions", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      this.errors
        ? _c("div", { staticStyle: { color: "red" } }, [
            _vm._v(
              "\n        \t\tPlease fix missing field or errors with the following " +
                _vm._s(this.errors) +
                "\n        \t"
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-md-center m-t-20" }, [
      _c("h5", [_c("b", [_vm._v("About the Trail")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }