var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "reviewModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "reviewModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm.auth
                  ? _c("div", [
                      !_vm.uploaded
                        ? _c("div", [
                            _c(
                              "form",
                              {
                                attrs: {
                                  id: "commentForm",
                                  action: "",
                                  name: "commentForm"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.title,
                                      expression: "title"
                                    }
                                  ],
                                  staticClass: "form-control mt-2",
                                  attrs: {
                                    type: "text",
                                    name: "title",
                                    placeholder: "title",
                                    required: ""
                                  },
                                  domProps: { value: _vm.title },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.title = $event.target.value
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.comment,
                                      expression: "comment"
                                    }
                                  ],
                                  staticClass: "form-control mt-4 mb-3",
                                  attrs: {
                                    name: "comment",
                                    rows: "5",
                                    cols: "70",
                                    placeholder: "review",
                                    required: ""
                                  },
                                  domProps: { value: _vm.comment },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.comment = $event.target.value
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml-3" }, [
                                  _vm._v("rating (stars)")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.rating,
                                        expression: "rating"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { width: "70px" },
                                    attrs: { id: "rating", name: "rating" },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.rating = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "1" } }, [
                                      _vm._v("1")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v("2")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v("3")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "4" } }, [
                                      _vm._v("4")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "5" } }, [
                                      _vm._v("5")
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploaded
                        ? _c("div", [
                            _c("p", [
                              _vm._v(
                                " Thanks, your review was sent, and will be reviewed. Please click Close. "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.error
                        ? _c("div", [
                            _c("p", [
                              _vm._v(
                                " Oops, an error occurred, please let us know about this! "
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.auth
                  ? _c("div", [
                      _c("p", { staticClass: "error-red" }, [
                        _vm._v(
                          "Please close and login before attempting to add a review"
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.closeComment }
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _vm.auth && !_vm.uploaded
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.submitComment }
                      },
                      [_vm._v("Submit")]
                    )
                  : _vm._e()
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "questionModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "questionModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                !_vm.uploaded
                  ? _c("div", [
                      _c(
                        "form",
                        {
                          attrs: {
                            id: "questionForm",
                            action: "",
                            name: "questionForm"
                          }
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.question,
                                expression: "question"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "question",
                              rows: "5",
                              cols: "70",
                              placeholder: "review",
                              required: ""
                            },
                            domProps: { value: _vm.question },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.question = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.uploaded
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          " Thanks, your question was submitted (and will be reviewed). Please close this pop-up."
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.error
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          " Oops, an error occurred, please let us know about this! "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.asked >= 1
                  ? _c("div", [
                      _c("p", [
                        _vm._v(" Sorry, we only allow 1 review per session")
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: { click: _vm.closeComment }
                  },
                  [
                    _vm._v(
                      ' class="btn btn-secondary" data-dismiss="modal">Close'
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.uploaded
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary ",
                        attrs: { type: "button" },
                        on: { click: _vm.submitComment }
                      },
                      [_vm._v("Submit")]
                    )
                  : _vm._e()
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list-group list-group-flush" },
        _vm._l(_vm.cmts, function(cmt) {
          return _c("li", { staticClass: "list-group-item" }, [
            _c("div", { staticClass: "d-inline-block widest" }, [
              _c("span", { staticClass: "d-inline-block" }, [_vm._v("by:")]),
              _vm._v(" "),
              _c("a", { attrs: { href: "/users/" + cmt.user_id } }, [
                _vm._v(_vm._s(cmt.username))
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-bolder d-inline-block text-uppercase"
                },
                [_vm._v(_vm._s(cmt.title))]
              ),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c("star-rank", {
                    attrs: { stars: cmt.stars, size: "14px", color: "orange" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "small", staticStyle: { float: "right" } },
                [_vm._v(_vm._s(cmt.created_at))]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-2" }, [_vm._v(_vm._s(cmt.comment))]),
            _vm._v(" "),
            cmt.down
              ? _c("p", { staticClass: "mt-2" }, [
                  _c("strong", [_vm._v("downside: ")]),
                  _vm._v(" " + _vm._s(cmt.down))
                ])
              : _vm._e(),
            _vm._v(" "),
            cmt.up
              ? _c("p", { staticClass: "mt-2" }, [
                  _c("strong", [_vm._v("upside: ")]),
                  _vm._v(" " + _vm._s(cmt.up))
                ])
              : _vm._e()
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal-header",
        staticStyle: { "background-color": "#000", color: "orange" }
      },
      [
        _c(
          "h5",
          { staticClass: "modal-title", attrs: { id: "reviewModalLabel" } },
          [_vm._v("Trail Review")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close"
            }
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "reviewModalLabel" } },
        [_vm._v("Ask a Question (to author & reviewers & followers)")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "card-header" }, [
      _vm._v("\n\t\t\t    Reviews and Comments\n\t\t\t    "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary  btn-sm m-2",
          attrs: {
            type: "button",
            "data-toggle": "modal",
            "data-type": "review",
            "data-target": "#reviewModal"
          }
        },
        [_vm._v("\n\t\t  \t\t\tAdd Review\n\t\t\t\t")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }