var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm.errors
      ? _c("div", { staticStyle: { color: "red" } }, [
          _vm._v(
            "\n        \tPlease fix missing field or errors with the following " +
              _vm._s(this.errors) +
              "\n        "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "trail-details" } }, [
      _c(
        "div",
        { staticClass: "container", staticStyle: { padding: "0 50px 20px 0" } },
        [
          _c("div", { staticClass: "row m-t-20" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "panel-body" }, [
                _c("label", { attrs: { for: "bio" } }, [
                  _vm._v("Bio (a paragraph or two about you, your goals)?")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.bio,
                      expression: "model.bio"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "bio", rows: "7" },
                  domProps: { value: _vm.model.bio },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.model, "bio", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row  m-t-20" }, [
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "level" } }, [
                _vm._v("Your level (outdoors)?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.level,
                      expression: "model.level"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "level" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "level",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.levels, function(l) {
                  return _c("option", { domProps: { value: l } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " + _vm._s(l) + "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-1" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "year" } }, [
                _vm._v("age (choose year of birth)?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.year,
                      expression: "model.year"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "year", required: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "year",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.years, function(y) {
                  return _c("option", { domProps: { value: y } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " + _vm._s(y) + "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "gender" } }, [_vm._v("gender?")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.gender,
                      expression: "model.gender"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "gender", required: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "gender",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.genders, function(g) {
                  return _c("option", { domProps: { value: g } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " + _vm._s(g) + "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-1" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "prov" } }, [
                _vm._v("province or state?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.prov,
                      expression: "model.prov"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "prov", required: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "prov",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.provinces, function(p) {
                  return _c("option", { domProps: { value: p.abbreviation } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t     " +
                        _vm._s(p.name) +
                        "\n\t\t\t\t\t\t  "
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-5" }, [
              _c("div", [
                _c("label", { attrs: { for: "municipality" } }, [
                  _vm._v("city or town?")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.municipality,
                      expression: "model.municipality"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "municipality", type: "text" },
                  domProps: { value: _vm.model.municipality },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.model, "municipality", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-1" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "country" } }, [
                _vm._v("country you reside in?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.country,
                      expression: "model.country"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "country", required: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "country",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.countries, function(c) {
                  return _c("option", { domProps: { value: c } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t       " + _vm._s(c) + "\n\t\t\t\t\t\t    "
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "summer" } }, [
                _vm._v("Favourite Summer Activity?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.favourite_summer,
                      expression: "model.favourite_summer"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "summer", required: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "favourite_summer",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.acts, function(f) {
                  return _c("option", { domProps: { value: f } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t       " + _vm._s(f) + "\n\t\t\t\t\t\t    "
                    )
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-1" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { attrs: { for: "winter" } }, [
                _vm._v("Favourite Winter Activity?")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.favourite_winter,
                      expression: "model.favourite_winter"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "winter", required: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "favourite_winter",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.acts, function(a) {
                  return _c("option", { domProps: { value: a } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t       " + _vm._s(a) + "\n\t\t\t\t\t\t    "
                    )
                  ])
                }),
                0
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      this.errors
        ? _c("div", { staticStyle: { color: "red" } }, [
            _vm._v(
              "\n        \t\tPlease fix missing field or errors with the following: " +
                _vm._s(this.errors) +
                "\n        \t"
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-md-center m-t-20" }, [
      _c("h5", [
        _c("b", [
          _vm._v("Build your profile so you can keep wishlists and plan trips")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }