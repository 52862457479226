<template>
<div>
	<!-- Photo Add Modal -->
	<div class="modal fade" id="photoModal" tabindex="-1" role="dialog" aria-labelledby="photoModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
		    <div class="modal-content">

			    <div class="modal-header" style="background-color: #000; color: orange;">
			        <h5 class="modal-title" id="photoModalLabel">Add Photo</h5>
			        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			            <span aria-hidden="true">&times;</span>
			        </button>
			    </div>

				<div class="modal-body">
				    <div id="photoUp">
						<h4 v-if="!hasImage" class="text-center m-t-20 m-b-30 m-r-30">{{ msg }}</h4>
					    <div v-if="auth" class="text-center m-l-20 m-t-20">
						    <image-uploader
							    :debug="1"
							    :maxWidth="1600"
							    :quality="0.9"
						        :preview="true"
						        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
						        :capture="false"
						        doNotResize="gif"
						        :autoRotate="false"
						        :maxSize=".9"
						        outputFormat="file"
						        @input="setImage"
							    >
							  	<label for="fileInput" slot="upload-label" class="ml-2">
							  		<i class="fas fa-camera"></i>
							        <span class="upload-caption">{{ hasImage ? 'Replace' : 'Upload' }}</span>
							    </label>
						    </image-uploader>
						</div>

				        <span v-if="hasImage && !uploaded" class="row justify-content-md-center m-r-10">
				        	<p class="m-l-20 text-center">Would you like to add a title (max 255 chars)</p>
				        	<input id="p-desc" type="text" class="form-control m-l-20" v-model="caption">
					       	<button class="btn btn-outline-secondary m-t-10" @click="upload">Save Photo!</button>
				        </span>

				        <span v-if="uploaded" class="row justify-content-md-center m-r-20">
					       	<button class="btn btn-outline-secondary m-t-10" @click="another">Add Another?</button>
				        </span>

				    	<div v-if="uploaded">
							<p class="m-t-20"> Thanks, your photo was sent, and will be reviewed. Please click close if finished, or, add another. </p>
				    	</div>

				    	<div v-if="error">
							<p class="m-t-20"> Oops, an error occurred, please let us know about this! </p>
				    	</div>

				    	<div v-if="!auth">
							<p class="m-t-20"> You must be signed in to add a photo, please close and login </p>
				    	</div>

				    	<div  v-if="errors" class="text-center m-l-20 m-t-10">
					    	<div style="color:red;">
					            Failed, please fix or report error: {{ errors }}
					    	</div>
			    		</div>

					    <div class="spinner-border text-center m-1" :class="{ invisible: isHidden }" role="status" id="spinner">
							<span class="sr-only">Loading...</span>
						</div>

				    	<button type="button" class="btn btn-secondary m-t-10" @click="closePhoto" data-dismiss="modal">Close</button>

				    </div>
				</div>
			</div>
		</div>
	</div>

	<!--  xv-lazy:image="image.src" -->

	<div v-if="Array.isArray(photos)">
		<ul class="photoThumbs">
	        <li class="thumb-li"
	          v-for="(image, index) in images" >
	          	<div v-if="!image.thumb || image.thumb == 'null'" :class="'photo-thumb'"></div>
	          	<div v-else :class="'photo-thumb linkable'" :style="{ backgroundImage: 'url(' + image.thumb + ')' }" @click="openGallery(index)"></div>
  	          <div v-if="what == 'user' || what == 'nearby'" class="mb-2 mt-1">
  	          	<a :href="image.hrf">
  	          	<span class="small-gray-link">{{ image.caption.split("near").pop().replace(/<\/?[^>]+(>|$)/g, "").substring(0,17) }}</span></a>
  	            <span v-if="what == 'user' && profile && userbox == 'want'" class="pl-3">
		             <a :href="'/profile/' + id + '/want-to-do/delete/' + image.id">
						<i class="flaticon-trash iaction wishlist fa-sm"></i>
		             </a>
		        </span>
                <span v-if="what == 'user' && profile && userbox == 'done'" class="pl-3">
 		             <a :href="'/profile/' + id + '/done/delete/' + image.id">
						<i class="flaticon-trash iaction wishlist fa-sm"></i>
		             </a>
	          	</span>
	          </div>
	          <div v-if="admin && what != 'user' && what != 'nearby'" class="feature-link">
	             <a :href="'/image/trail/feature/' + id + image.src.substr(image.src.lastIndexOf('/'))">feature | </a>
	             <a :href="'/image/trail/delete/' + id + image.src.substr(image.src.lastIndexOf('/'))"> delete</a>
	          </div>

	        </li>
	  	</ul>

		<LightBox 
			:images="photos"
			ref="lightbox"
			:show-thumbs="false"
	        :show-caption="true"
	        :show-light-box="false">
	    </LightBox>
    </div>

</div>
</template>

<script>    
	import LightBox from 'vue-image-lightbox';
    import prettyJSON from "../utilities/prettyJson.js";
    require('vue-image-lightbox/dist/vue-image-lightbox.min.css');

    export default {
    	name: 'photo-box',
    	components: {
		    LightBox,
		},
        props: {
			photos: {
				default: null,
			},
			hgt: {
				default: '135px',
			},
			id: {
				default: null,
			},
			what: {
				default: 'trails',
			},
			auth: {
			    default: 0
			 },
			admin: {
			    default: 0
			},
			profile: {
				default: 0
			},
			userbox: {
				default: null,
			},
        },
        data() {
            return {
        		images: [],
        		pad: 1,
        		msg: "Add Photo",
			    hasImage: false,
			    image: null,
			    loading: false,
			    uploaded: false,
			    errors: '',
			    error: false,
			    caption: '',
			    isHidden: true,
        	}
        },
        methods: {
		    openGallery(index) {
     			this.$refs.lightbox.showImage(index);
   			},
   			closePhoto() {
        		this.uploaded = false;
        		this.error = false;
        		this.hasImage = false;
        		this.loading = false;
        		this.errors = '';
        		this.image = null;
        		this.isHidden = true;
        		this.caption = '';

        		$('#photoUp .img-preview').hide();
        		location.reload();
        	},
  			setImage: function(output) {
            	$('#photoUp .img-preview').show();

            	// 'save' is next
			    this.hasImage = true; // is in preview
			    this.image = output; // the image (as file)
			    this.uploaded = false; // not sent to server

				console.log("in SetImage");
			    if (this.image) {
			    	console.log("Image from setImage (preview)");
			    	console.log(this.image);
			    }
			},
			complete: function(output) {
			    this.hasImage = true;
			    this.isHidden = true;
			    this.image = output;

			    console.log("we have a complete notification");
			    console.log(this.image);
			},
			another() {
				this.hasImage = false; 
				this.errors = '';
				this.isHidden = true;
				this.uploaded = false;
				$('#photoUp .img-preview').hide();
			},
			upload() {
				console.log ("uploading ...");
				let formData = new FormData();
				var self = this;
				var uri = this.what == 'trails' ? '/trails/add-photo/' : '/users/add-photo/';
				this.uploaded = false; // server upload - start
				this.errors = '';

	            formData.set('id', this.id);
	            formData.set('caption', this.caption);

	            this.isHidden = false;

	            formData.append('photo', this.image, this.image.name);
	            axios.post( uri,
	                formData,
	                { headers: 
	                	{ 'Content-Type': 'multipart/form-data' } 
	                }
	            ).then(response => {
                    self.uploaded = true; 
                    self.isHidden = true;
                    console.log("upload response:" + JSON.stringify(response.data));
                    if (typeof response.data.thumb !== 'undefined') {
                    	this.images.push(response.data);
                    }
                    this.caption = '';
                }).catch(error => {
                	this.isHidden = true;
                    if (JSON.stringify(error) !== '{}' && error.response !== null) {
                    	var errMsg = 'error uploading to server, possibly size is too large';
                        if (error.response.data){
                            errMsg = error.response.data;
                            console.log("Photo upload failure: " + JSON.stringify(error.response.data)); 
                        }
                        self.errors = errMsg;
                    } 
                });
			},
		},
		mounted() {
		 	this.images = this.photos;
			//console.log("photo array:" + JSON.stringify(this.photos));
		}
    }    
</script>
<style>
 .modal-footer {
 	  background-color: white;
 }
 .photoThumbs {
 	  padding-inline-start:0;
 	  line-height: 0.5rem;
 }
 .photo-thumb {
	  background-color: #cccccc;
	  height: 130px;
	  width: 170px;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;
	  position: relative;
}
.thumb-li {
	display: inline-block;
	margin-right: 2px;
	margin-top: 2px;
	padding: 0;
}
.feature-link {
	z-index: 1000001;
	color: white;
	opacity: .6;
	height: .8rem;
	padding-top:3px;
}
 </style>

