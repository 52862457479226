<template>
  <div>

    <form-wizard @on-complete="onComplete" ref="wizard" shape="circle" error-color="#e74c3c" color="#004080">
    	    <h4 slot="title" class="m-t-30">Your Adventure Profile</h4>
            <tab-content title="About You" icon="fa fa-comment" :before-change="() => validate('step-about')">
                <step-about ref="step-about"  
                        @on-validate="onStepValidate" 
                        :uid="uid"
                        :editModel="editModel">                        
                </step-about>
            </tab-content>

            <tab-content title="Image" icon="fa fa-camera" :before-change="() => validate('step-photo')">
                <step-photo ref="step-photo"  
                        @on-validate="onStepValidate"
                        :finalModel="finalModel"
                        :editModel="editModel"
                        :uid="uid">                        
                </step-photo>
            </tab-content> 

            <tab-content title="Buzz" icon="fa fa-bullhorn">
                <step-buzz v-if="!done" ref="step-buzz"  
                        @on-validate="onStepValidate"
                        :finalModel="finalModel" 
                        :editModel="editModel"
                        :uid="uid">                        
                </step-buzz>
                <div v-if="done" class="text-center">
			    	<h3 class="text-center mt-3">We are done, thanks!</h3>
			    	<p> Visit your <strong><a :href="'/users/' + uid">profile</a></strong> to see it!</p>
			    </div>
            </tab-content>

            <button class="btn btn-success m-l-50" slot="prev" v-show="!isLastStep()">Back</button>
            <button class="btn btn-success m-5-50" slot="next">Next</button>
            <button class="btn btn-success" slot="finish" v-show="isLastStep() && !done">Finish</button>                    
    </form-wizard>


  </div>
</template>

<script>    
    import 'vue-form-wizard/dist/vue-form-wizard.min.css';
    import StepAbout from './user/stepAbout.vue';
    import StepPhoto from './user/StepPhoto.vue';
    import StepBuzz from './user/StepBuzz.vue';
    import prettyJSON from "../utilities/prettyJson.js";

    export default {
        name: 'user-add-wizard',
        props: {
			uid: {
				default: null,
			},
			editModel: {
				default: null,
			},
        },
        components: {
            StepAbout,
            StepPhoto,
            StepBuzz,
        },
        data() {
            return {
                finalModel: '',
                done: false,
            };
        },
        computed: {
            prettyJSON() {
                return prettyJSON(this.finalModel);
            }
        },
        methods: {
            onComplete() {
			    this.done = true;
			    console.log(prettyJSON(this.finalModel));
		    },
		    forceClearError() {
		      	this.$refs.wizard.tabs[this.activeTabIndex].validationError = null;
		    },
		    validate(ref) {
		      	return this.$refs[ref].validate(); // send to child (step) for validation
		    },
		    onStepValidate(validated, model) { // validated, child returns bool
		      if (validated) {
		          this.finalModel = { ...this.finalModel, ...model };
		      }
		      
		      return true;
		    },
		    isLastStep(){
			    if(this.$refs.wizard) {
			     	return this.$refs.wizard.isLastStep
			    }
			    return false
			}
        },
        mounted() {
            Event.listen('skip',  () => {
            	this.$refs.wizard.nextTab();
            });

            //console.log(this.editModel);
            //console.log("ID with user wizard:" + this.uid);
        }
    }    
</script>
<style>
  .fa {
  	font-size: 14px;
  }
</style>
