<template>
    
    <div>
        <div class="row justify-content-md-center m-t-20">
            <h5><b>Build your profile so you can keep wishlists and plan trips</b></h5>
        </div>

        <div v-if="errors" style="color:red;">
        	Please fix missing field or errors with the following {{ this.errors }}
        </div>

        <div id="trail-details">
            <div class="container" style="padding: 0 50px 20px 0">

            	<div class="row m-t-20">
                    <div class="col-md-12">
                        <div class="panel-body">
                        	<label for="bio">Bio (a paragraph or two about you, your goals)?</label>
                            <textarea id="bio" rows="7" class="form-control" v-model="model.bio"></textarea>
                        </div>
                    </div>
                </div>

                <div  class="row  m-t-20">
			        <div class="col-md-5">
			        	<label for="level">Your level (outdoors)?</label>
						<select id="level" class="form-control" v-model="model.level">
						  <option v-for="l in levels" v-bind:value="l">
						     {{ l }}
						  </option>
						</select>
			        </div>

                    <div class="col-md-1"></div>

                    <div class="col-md-5">
			        	<label for="year">age (choose year of birth)?</label>
						<select id="year" required class="form-control" v-model="model.year">
						  <option v-for="y in years" v-bind:value="y">
						     {{ y }}
						  </option>
						</select>
			        </div>
                </div>

			    <div class="row">

			        <div class="col-md-5">
			        	<label for="gender">gender?</label>
						<select id="gender" required class="form-control" v-model="model.gender">
						  <option v-for="g in genders" v-bind:value="g">
						     {{ g }}
						  </option>
						</select>
			        </div>

			        <div class="col-md-1"></div>

			        <div class="col-md-5">
			        	<label for="prov">province or state?</label>
						<select id="prov" required class="form-control" v-model="model.prov">
						  <option v-for="p in provinces" v-bind:value="p.abbreviation">
						     {{ p.name }}
						  </option>
						</select>
			        </div>
			    </div>

			    <div class="row">

			    	<div class="col-md-5">
			            <div>
			            	<label for="municipality">city or town?</label>
			                <input id="municipality" type="text" class="form-control" v-model="model.municipality">
			            </div>
			        </div>

			        <div class="col-md-1"></div>

			    	<div class="col-md-5">
			    		<label for="country">country you reside in?</label>
						<select id="country" required class="form-control" v-model="model.country">
						    <option v-for="c in countries" v-bind:value="c">
						       {{ c }}
						    </option>
						</select>
					</div>

			    </div>

			    <div class="row">

			    	<div class="col-md-5">
			    		<label for="summer">Favourite Summer Activity?</label>
						<select id="summer" required class="form-control" v-model="model.favourite_summer">
						    <option v-for="f in acts" v-bind:value="f">
						       {{ f }}
						    </option>
						</select>
			        </div>

			        <div class="col-md-1"></div>

			    	<div class="col-md-6">
			    		<label for="winter">Favourite Winter Activity?</label>
						<select id="winter" required class="form-control" v-model="model.favourite_winter">
						    <option v-for="a in acts" v-bind:value="a">
						       {{ a }}
						    </option>
						</select>
					</div>

			    </div>

            </div>   
            
            <div v-if="this.errors" style="color:red;">
        		Please fix missing field or errors with the following: {{ this.errors }}
        	</div>  
        </div>
    
    </div>  

</template>

<script>
    import provs from '../../utilities/provState.js';
    import activities from '../../utilities/activities.js';
    import prettyJSON from "../../utilities/prettyJson.js";

    export default {
        name: 'step-about',
        props: {
			uid: {
				default: null,
			},
			editModel: {
				default: null,
			},
        },
        data() {
            return {
               model: {
                    bio: '',
                    level: '',
                    gender: '',
                    year: '',
                    prov: '',
                    municipality: '',
                    gender: '',
                    country: '',
                    favourite_summer: '',
                    favourite_winter: '',
                },
                years: [],
                success: '',
                acts: [],
                provinces: '',
                errors: '',
                genders: ['male', 'female'],
                countries: ['Canada', 'USA', 'UK', 'Australia', 'France', 'Germany', 'Holland', 'Ireland', 'New Zealand', 'China', ' Other Asia', 'Other Europe', 'Africa'],
                levels: ['beginner', 'enthusiast', 'advanced', 'extreme'],
                //when:  ['mostly on vacation', 'multi-day back-country', 'multi-day front-country', 'mostly day trips', 'athlete/racer'],
            }
        },
        methods: {
            async validate() {
	              var valid = true;

	              console.log("validating about"); 

	              if (!this.model.bio) {
	                  valid = false; // error
	                  this.errors += ' / bio ';
	              }

	              if (!this.model.year || !this.validNum( this.model.year )) {
	                  valid = false; // error
	                  this.errors += ' / year of birth ';
	              }

	              if (!this.model.gender) {
	                  valid = false; // error
	                  this.errors += ' / gender ';
	              }

	              if (!this.model.prov) {
	                  valid = false; // error
	                  this.errors += ' / prov or state ';
	              }

	              if (!this.model.level) {
	                  valid = false; // error
	                  this.errors += ' / expertise level ';
	              }

	              await this.upload();
	              
				  valid = this.uploaded;
				  console.log("upload status: " + valid); 

              	  this.$emit('on-validate', valid, this.model); // see @on-validate method in parent
                  return valid;

            },
            validNum ( num ) {
            	var reg = /^\d+$/;
            	return reg.test( num );
            },
            upload() {
				let formData = new FormData();
				let self = this;
				self.uploaded = false; // server upload - start
	            
	            formData.set('user', JSON.stringify(self.model));
	            formData.set('id', this.uid );

	            return axios.post( '/users/add-profile', formData,
	            ).then(response => {
                    self.uploaded = true; 
		            console.log('profile add SUCCESS!!');
                })
		        .catch(error => {
		        	self.uploaded = false; 
                    if (error.response !== null) {
                        if (error.response.data){
                            self.errors = error.response.data;
                            console.log("User upload failure: " + JSON.stringify(error.response.data)); 
                        }
                    } else {
                        console.log("ERROR:" + error); 
                    }
                });
			}
		},
        mounted() {
        	var now = (new Date()).getFullYear() - 18;
			var end = 1930;
			for(var i = now; i >= end; i--){
				this.years.push(i);
			}
			this.provinces = provs;
			this.acts = activities;

			// console.log(this.editModel);

			if (this.editModel) {
				    this.model.bio = this.editModel.bio;
                    this.model.level = this.editModel.level;
                    this.model.gender = this.editModel.gender;
                    this.model.year = this.editModel.year;
                    this.model.prov = this.editModel.prov;
                    this.model.municipality = this.editModel.municipality;
                    this.model.country = this.editModel.country;
                    this.model.favourite_summer = this.editModel.favourite_summer;
                    this.model.favourite_winter = this.editModel.favourite_winter;
			}
        }
    }

</script>