<template>
  <div>	
  	<p v-if="error" class="error-red">{{ errmsg }}</p>
    <form>
    <div class="row">
    	<div class="col-md-12">
        	<h5><b>Where is Your Trail?</b></h5>
    	</div>
    </div>

    <div class="row" v-if="error">
    	<div class="col-md-12">
        	<p class="text-red">Please enter or corrrect all fields</p>
    	</div>
    </div>

    <div class="row justify-content-center align-items-center">
        <div class="col-md-5 m-t-20">
            <div>
            	<label for="name">name of trail</label>
                <input id="name" required type="text" class="form-control" v-on:blur="authcheck" v-model="model.name">
            </div>
        </div>
    </div>

    <div class="row justify-content-center align-items-center">
        <div class="col-md-5 m-t-20">
        	<label for="prov">prov/state</label>
			<select id="prov" required class="form-control" v-model="model.prov">
			  <option v-for="p in provinces" v-bind:value="p.abbreviation">
			     {{ p.name }}
			  </option>
			</select>
        </div>
    </div>

    <div class="row justify-content-center align-items-center">
        <div class="col-md-5 m-t-20">
        	<label for="town">nearest city or town</label>
            <div>
                <input id="town" v-on:blur="authcheck" type="text" class="form-control" v-model="model.municipality">
            </div>
        </div>
    </div>
	</form>
  </div>

</template>

<script>

    import provs from '../../utilities/provState.js';
    import rtest from '../../utilities/validate.js';

    export default {
        name: 'step-location',
 		props: {
             auth: {
			    default: false,
			    type: Boolean
			 },
        },
        data() {
            return {
            	error: false,
            	errmsg: '',
                model: {
                    name: '',
                    country: 'Canada',
                    prov: '',
                    municipality: '',
                    region : '',
                },
                provinces: ''
            }
        },
        methods: {
            validate() {
                var valid = true;
                this.error = false;
                this.errsg = '';

                if (!this.model.name || !this.model.prov || !this.model.municipality) {
                	this.error = true;
                	this.errmsg = "you are missing a field, you left it empty";
                	valid = false;
                }
                if (!rtest.validTrailName(this.model.name)) {
                	this.error = true;
                	this.errmsg += "check the trail name, does it have weird characters perhaps?";
                	valid = false;
                }
                if (!rtest.validPlace(this.model.municipaliy)) {
                	this.error = true;
                	this.errmsg += "check municipaality, does it haveany weird characters?";
                	valid = false;
                }
                if (!this.auth) {
                	this.error = true;
                	valid = false;
                }

                this.$emit('on-validate', valid, this.model);
                return valid;
            },
            authcheck() {
            	if (!this.auth) {
            		this.error = true;
            		this.errmsg = "um sorry, please login before continuing";
            	}
            }
		},
        mounted() {
        	this.provinces = provs;
        	console.log("step 1 mounted");

        }
    }

</script>