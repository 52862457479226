<template>

<div id="photoUp">
	<h4 v-if="!hasImage" class="text-center m-t-20 m-b-30 m-r-30">{{ msg }}</h4>
    <div class="text-center m-l-20 m-t-20">
	    <image-uploader
		    :debug="1"
		    :maxWidth="1500"
		    :quality="0.8"
	        :preview="true"
	        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
	        capture="environment"
	        doNotResize="gif"
	        :autoRotate="true"
	        outputFormat="file"
	        @input="setImage"
		    >
		  	<label for="fileInput" slot="upload-label" class="ml-2">
		  		<i class="fas fa-camera"></i>
		        <span class="upload-caption">{{ hasImage ? 'Replace' : 'Upload' }}</span>
		    </label>
	    </image-uploader>
	</div>

	<div>
        <div v-if="hasImage && !uploaded" class="row justify-content-md-center m-t-40 m-b-30 m-r-40">
	       	<button class="btn btn-outline-secondary m-t-20" @click="upload">Save this Photo!</button>
        </div>
    </div>

    <div>
        <div v-if="uploaded" class="row justify-content-md-center m-t-40 m-b-30 m-r-40">
	       	<button class="btn btn-outline-secondary m-t-20" @click="another">Click to replace, or NEXT if done</button>
        </div>
    </div>

    <div  v-if="errors" class="text-center m-l-20 m-t-20">
    	<div style="color:red;">
            Please fix missing field or errors with the following: {{ this.errors }}
    	</div>
    </div>

</div>  

</template>

<script>
    export default {
        name: 'step-photo',
        props: {
			uid: {
				default: null,
			},
			editModel: {
				default: null,
			},
        },
        data() {
            return {
                msg: "Add Photo",
			    hasImage: false,
			    image: null,
			    loading: false,
			    uploaded: false,
			    errors: '',
			    id: '',
            }
        },
        methods: {
            validate() {
                var valid = true;
                this.$emit('on-validate', valid, this.model);
                return valid;
            },
            setImage: function(output) {
            	$('#photoUp .img-preview').show();

            	// 'save' is next
			    this.hasImage = true; // is in preview
			    this.image = output; // the image (as file)
			    this.uploaded = false; // not sent to server

				console.log("in SetImage");
			    if (this.image) {
			    	//console.log("Image from setImage (preview)");
			    	//console.log(this.image);
			    }
			},
			complete: function(output) {
			    this.hasImage = true;
			    this.image = output;

			    //console.log("we have a completed notification");
			    //console.log(this.image);
			},
			another() {
				this.hasImage = false; 
				this.uploaded = false;
				$('#photoUp .img-preview').hide();
			},
			upload() {
				let formData = new FormData();
				var self = this;
				this.uploaded = false; // server upload - start
	            
	            formData.set('id', this.id);
	            formData.append('photo', this.image, this.image.name);

	            axios.post( '/users/add-photo',
	                formData,
	                { headers: 
	                	{ 'Content-Type': 'multipart/form-data' } 
	                }
	            ).then(response => {
                    self.uploaded = true; 
		            //console.log('SUCCESS!! ' + response.data.id);
                })
		        .catch(error => {
                    if (error.response !== null) {
                        if (error.response.data){
                            self.errors = error.response.data.errors;
                            console.log("Photo upload failure: " + JSON.stringify(error.response.data)); 
                        }
                    } else {
                        console.log("ERROR:" + error); 
                    }
                });

                return this.uploaded;
			},
		},
        mounted() {
        	//console.log("step photo mounted");
        	this.id = this.uid;
        }
    };

</script>

<style>
	#fileInput {
	  display: none;
	}
	.fas {
		font-size: 2rem;
	}
</style>