var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-wizard",
        {
          ref: "wizard",
          attrs: { color: "#666" },
          on: { "on-complete": _vm.onComplete }
        },
        [
          _c(
            "h4",
            { staticClass: "m-t-30", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("ADD A TRAIL")]
          ),
          _vm._v(" "),
          _vm.errmsg
            ? _c("p", [_vm._v(" " + _vm._s(_vm.errmsg) + " ")])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "Location",
                icon: "fa fa-map-marker-alt",
                "before-change": function() {
                  return _vm.validate("step-location")
                }
              }
            },
            [
              _c("step-location", {
                ref: "step-location",
                attrs: { auth: _vm.auth },
                on: { "on-validate": _vm.onStepValidate }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "Details",
                icon: "fa fa-mountain",
                "before-change": function() {
                  return _vm.validate("step-about")
                }
              }
            },
            [
              _c("step-about", {
                ref: "step-about",
                attrs: { finalModel: _vm.finalModel },
                on: { "on-validate": _vm.onStepValidate }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "GPS",
                icon: "fa fa-route",
                "before-change": function() {
                  return _vm.validate("step-gps")
                }
              }
            },
            [
              _c("step-gps", {
                ref: "step-gps",
                attrs: { auth: _vm.auth, finalModel: _vm.finalModel },
                on: { "on-validate": _vm.onStepValidate }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            { attrs: { title: "Photos", icon: "fa fa-camera" } },
            [
              !_vm.done
                ? _c("step-photo", {
                    ref: "step-photo",
                    attrs: {
                      finalModel: _vm.finalModel,
                      id: _vm.id,
                      auth: _vm.auth
                    },
                    on: { "on-validate": _vm.onStepValidate }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.done
                ? _c("div", { staticClass: "text-center" }, [
                    _c("h3", { staticClass: "text-center mt-3" }, [
                      _vm._v("We are done, thanks!")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(" Visit your "),
                      _c("a", { attrs: { href: "/trails/" + _vm.id } }, [
                        _c("u", [_vm._v("trail")])
                      ]),
                      _vm._v(" to see it!")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        " It isn't approved yet, but we'll review it before approving for display!"
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLastStep(),
                  expression: "!isLastStep()"
                }
              ],
              staticClass: "btn btn-success m-l-50",
              attrs: { slot: "prev" },
              slot: "prev"
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success m-5-50",
              attrs: { slot: "next" },
              slot: "next"
            },
            [_vm._v("Next")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLastStep() && !_vm.done,
                  expression: "isLastStep() && !done"
                }
              ],
              staticClass: "btn btn-success",
              attrs: { slot: "finish" },
              slot: "finish"
            },
            [_vm._v("Finish")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }