var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-wizard",
        {
          ref: "wizard",
          attrs: {
            shape: "circle",
            "error-color": "#e74c3c",
            color: "#004080"
          },
          on: { "on-complete": _vm.onComplete }
        },
        [
          _c(
            "h4",
            { staticClass: "m-t-30", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("Your Adventure Profile")]
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "About You",
                icon: "fa fa-comment",
                "before-change": function() {
                  return _vm.validate("step-about")
                }
              }
            },
            [
              _c("step-about", {
                ref: "step-about",
                attrs: { uid: _vm.uid, editModel: _vm.editModel },
                on: { "on-validate": _vm.onStepValidate }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "Image",
                icon: "fa fa-camera",
                "before-change": function() {
                  return _vm.validate("step-photo")
                }
              }
            },
            [
              _c("step-photo", {
                ref: "step-photo",
                attrs: {
                  finalModel: _vm.finalModel,
                  editModel: _vm.editModel,
                  uid: _vm.uid
                },
                on: { "on-validate": _vm.onStepValidate }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            { attrs: { title: "Buzz", icon: "fa fa-bullhorn" } },
            [
              !_vm.done
                ? _c("step-buzz", {
                    ref: "step-buzz",
                    attrs: {
                      finalModel: _vm.finalModel,
                      editModel: _vm.editModel,
                      uid: _vm.uid
                    },
                    on: { "on-validate": _vm.onStepValidate }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.done
                ? _c("div", { staticClass: "text-center" }, [
                    _c("h3", { staticClass: "text-center mt-3" }, [
                      _vm._v("We are done, thanks!")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(" Visit your "),
                      _c("strong", [
                        _c("a", { attrs: { href: "/users/" + _vm.uid } }, [
                          _vm._v("profile")
                        ])
                      ]),
                      _vm._v(" to see it!")
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLastStep(),
                  expression: "!isLastStep()"
                }
              ],
              staticClass: "btn btn-success m-l-50",
              attrs: { slot: "prev" },
              slot: "prev"
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success m-5-50",
              attrs: { slot: "next" },
              slot: "next"
            },
            [_vm._v("Next")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLastStep() && !_vm.done,
                  expression: "isLastStep() && !done"
                }
              ],
              staticClass: "btn btn-success",
              attrs: { slot: "finish" },
              slot: "finish"
            },
            [_vm._v("Finish")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }