var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.error
      ? _c("p", { staticClass: "error-red" }, [_vm._v(_vm._s(_vm.errmsg))])
      : _vm._e(),
    _vm._v(" "),
    _c("form", [
      _vm._m(0),
      _vm._v(" "),
      _vm.error ? _c("div", { staticClass: "row" }, [_vm._m(1)]) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row justify-content-center align-items-center" },
        [
          _c("div", { staticClass: "col-md-5 m-t-20" }, [
            _c("div", [
              _c("label", { attrs: { for: "name" } }, [
                _vm._v("name of trail")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.name,
                    expression: "model.name"
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "name", required: "", type: "text" },
                domProps: { value: _vm.model.name },
                on: {
                  blur: _vm.authcheck,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "name", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row justify-content-center align-items-center" },
        [
          _c("div", { staticClass: "col-md-5 m-t-20" }, [
            _c("label", { attrs: { for: "prov" } }, [_vm._v("prov/state")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.prov,
                    expression: "model.prov"
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "prov", required: "" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.model,
                      "prov",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.provinces, function(p) {
                return _c("option", { domProps: { value: p.abbreviation } }, [
                  _vm._v("\n\t\t\t     " + _vm._s(p.name) + "\n\t\t\t  ")
                ])
              }),
              0
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row justify-content-center align-items-center" },
        [
          _c("div", { staticClass: "col-md-5 m-t-20" }, [
            _c("label", { attrs: { for: "town" } }, [
              _vm._v("nearest city or town")
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.municipality,
                    expression: "model.municipality"
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "town", type: "text" },
                domProps: { value: _vm.model.municipality },
                on: {
                  blur: _vm.authcheck,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "municipality", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h5", [_c("b", [_vm._v("Where is Your Trail?")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("p", { staticClass: "text-red" }, [
        _vm._v("Please enter or corrrect all fields")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }