<template>
   <div class="d-inline-block">
   	<ul class="m-0 p-0">
   		<li v-for="star in stars" class="star">
   			<i class="flaticon-star-1" :style="{ 'color': color, 'font-size': size }"></i>
   		</li>
   	</ul>
   </div>
</template>

<script>    
    export default {
    	name: 'star-rank',
        props: [
			'stars',
			'color',
			'size',
        ],      	
    }    
</script>

<style>
 .star {
     display:  inline;
     padding-left:  3px;
 }
</style>
