var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.auth
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-offset-3 col-md-6" }, [
              _vm.questionGps
                ? _c("div", { staticClass: "m-b-50" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary m-t-20",
                        on: {
                          click: function($event) {
                            return _vm.noGps(true)
                          }
                        }
                      },
                      [_vm._v("NO")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary m-t-20",
                        on: {
                          click: function($event) {
                            return _vm.noGps(false)
                          }
                        }
                      },
                      [_vm._v("YES")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.questionLatLng
                ? _c("div", { staticClass: "m-b-50" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary m-t-20",
                        on: { click: _vm.noLatLng }
                      },
                      [_vm._v("NO LAT/LNG")]
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.haveGps
            ? _c("div", { staticClass: "row m-t-30 m-b-30" }, [
                _c("div", { staticClass: "col-md-offset-3 col-md-6" }, [
                  _c("input", {
                    ref: "file",
                    staticClass: "form-control",
                    attrs: { type: "file", id: "file" },
                    on: {
                      change: function($event) {
                        return _vm.handleFileUpload()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm.errorGps
                  ? _c("div", [_c("p", [_vm._v("Not a valid file type!")])])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMap,
                  expression: "showMap"
                }
              ],
              staticClass: "row m-t-20 m-b-30"
            },
            [
              _c("div", { staticClass: "col-md-12", attrs: { id: "gps-map" } }),
              _vm._v(" "),
              _vm.gpsFile && !_vm.errorGps && !_vm.model.id
                ? _c("div", [
                    _c("p", { staticClass: "text-center m-t-20 m-l-30" }, [
                      _vm._v(
                        " Does the GPX file look alright? Fix and re-upload if not."
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-secondary m-t-10 m-l-20",
                          on: { click: _vm.sendIt }
                        },
                        [_vm._v("Save GPS!")]
                      )
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.submitOK
            ? _c("div", [
                _c("h5", { staticClass: "text-center" }, [_vm._v("Success!")]),
                _vm._v(" "),
                this.action == "addGps"
                  ? _c("div", [
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v(" Thanks, click close now.")
                      ])
                    ])
                  : _c("div", [
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v(
                          " Thanks, now click next below to add any photos"
                        )
                      ])
                    ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.haveLatLng
            ? _c("div", { staticClass: "row m-t-30" }, [
                _c("div", { staticClass: "col-md-offset-3 col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.lat,
                        expression: "model.lat"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      pattern:
                        "^-?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$",
                      placeholder: "latitude (dd.dddd)"
                    },
                    domProps: { value: _vm.model.lat },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "lat", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.haveLatLng
            ? _c("div", { staticClass: "row m-t-10 m-b-30" }, [
                _c("div", { staticClass: "col-md-offset-3 col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.lng,
                        expression: "model.lng"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      pattern:
                        "^-?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[1-7][0-9])(?:(?:\\.[0-9]{1,6})?))$",
                      placeholder: "longitude (dd.dddd)"
                    },
                    domProps: { value: _vm.model.lng },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "lng", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          this.errors
            ? _c("div", { staticClass: "row m-t-10 m-b-30" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-offset-3 col-md-9",
                    staticStyle: { color: "red" }
                  },
                  [
                    _vm._v(
                      "\n\t            Please fix missing field or errors, " +
                        _vm._s(this.errors) +
                        "\n        \t"
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.auth
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "col-md-offset-3 col-md-9",
              staticStyle: { color: "red" }
            },
            [
              _vm._v(
                "\n\t            Please sign in to be able to upload GPS (.gpx)\n        "
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Do you have GPS/GPX?")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("b", [_vm._v("OK but do you have trailhead co-ordinates?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }