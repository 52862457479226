var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.title
      ? _c("div", [
          _c("p", { staticClass: "mb-2 text-center" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.fetched && _vm.title.startsWith("We are shuffling")
      ? _c("div", { staticClass: "mb-2 btn-grp text-center" }, [
          _vm.zoom > -2
            ? _c(
                "button",
                {
                  staticClass:
                    "btn btn-sm btn-success text-white font-weight-bold",
                  on: { click: _vm.zoomIn }
                },
                [_vm._v("Closer In")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.zoom < 2
            ? _c(
                "button",
                {
                  staticClass:
                    "btn btn-sm btn-secondary text-white font-weight-bold",
                  on: { click: _vm.zoomOut }
                },
                [_vm._v("Farther Out")]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.fetched
      ? _c("div", [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("photo-box", {
                attrs: { photos: _vm.images, hgt: 100, what: "nearby" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.errors
            ? _c(
                "div",
                { staticClass: "ml-3", attrs: { id: "download-msg" } },
                [
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.errors))
                  ])
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }