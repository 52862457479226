<template>
   <div>
		<!-- Review Modal -->
		<div class="modal fade" id="reviewModal" tabindex="-1" role="dialog" aria-labelledby="reviewModalLabel" aria-hidden="true">
		  <div class="modal-dialog" role="document">
		    <div class="modal-content">
		      <div class="modal-header" style="background-color: #000; color: orange;">
		        <h5 class="modal-title" id="reviewModalLabel">Trail Review</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
			    <div v-if="auth">
			      	<div v-if="!uploaded">
				        <form id="commentForm" action="" name="commentForm">
		    	            <input type="text" class="form-control mt-2" name="title" placeholder="title" v-model="title" required>
				        	<textarea name="comment" rows="5" cols="70"  class="form-control mt-4 mb-3" placeholder="review" v-model="comment" required/>
				        	<span class="ml-3">rating (stars)</span>
							<select id="rating" class="form-control" name="rating" v-model="rating" style="width: 70px;">
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
							</select>
				        </form>
			    	</div>
			    	<div v-if="uploaded">
						<p> Thanks, your review was sent, and will be reviewed. Please click Close. </p>
			    	</div>
			    	<div v-if="error">
						<p> Oops, an error occurred, please let us know about this! </p>
			    	</div>
			    </div>
			    <div v-if="!auth">
			    	<p class="error-red">Please close and login before attempting to add a review</p>
			    </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" @click="closeComment" data-dismiss="modal">Close</button>
		        <button v-if="auth && !uploaded" type="button" class="btn btn-primary" @click="submitComment">Submit</button>  
		      </div>
		    </div>
		  </div>
		</div>

		<!-- Question Modal -->
		<div class="modal fade" id="questionModal" tabindex="-1" role="dialog" aria-labelledby="questionModalLabel" aria-hidden="true">
		  <div class="modal-dialog" role="document">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="reviewModalLabel">Ask a Question (to author & reviewers & followers)</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		      	<div v-if="!uploaded">
			        <form id="questionForm" action="" name="questionForm">
			        	<textarea name="question" rows="5" cols="70"  class="form-control" placeholder="review" v-model="question" required/>
			        </form>
		    	</div>
		        <div v-if="uploaded">
					<p> Thanks, your question was submitted (and will be reviewed). Please close this pop-up.</p>
		    	</div>
		    	<div v-if="error">
					<p> Oops, an error occurred, please let us know about this! </p>
		    	</div>
		    	<div v-if="asked >= 1">
					<p> Sorry, we only allow 1 review per session</p>
		    	</div>
		      </div>
		      <div class="modal-footer">
		        <button type="button"  @click="closeComment"> class="btn btn-secondary" data-dismiss="modal">Close</button>
		        <button v-if="!uploaded" type="button" class="btn btn-primary " @click="submitComment">Submit</button>  
		      </div>
		    </div>
		  </div>
		</div>


		<!-- Show All Comments -->
		<div class="card" >
			<h5 class="card-header">
			    Reviews and Comments
			    <button type="button" class="btn btn-primary  btn-sm m-2" data-toggle="modal"  data-type="review" data-target="#reviewModal">
		  			Add Review
				</button>
				<!-- <button type="button" class="btn btn-primary  btn-sm m-2" data-toggle="modal" data-type="question" data-target="#questionwModal">
		  			Ask Question
				</button> -->
			</h5>
			<ul class="list-group list-group-flush">
			    <li v-for="cmt in cmts" class="list-group-item">
			    	<div class="d-inline-block widest">
			    		<span class="d-inline-block">by:</span> <a :href="'/users/' + cmt.user_id ">{{ cmt.username }}</a>
			    	    <span class="font-weight-bolder d-inline-block text-uppercase">{{ cmt.title }}</span> 
			    	    <span><star-rank :stars="cmt.stars" :size="'14px'" :color="'orange'"></star-rank></span> 
			    	    <span class="small" style="float:right;">{{ cmt.created_at }}</span>
			    	</div>
					<p class="mt-2">{{ cmt.comment }}</p>
					<p v-if="cmt.down" class="mt-2"><strong>downside: </strong> {{ cmt.down }}</p>
					<p v-if="cmt.up" class="mt-2"><strong>upside: </strong> {{ cmt.up }}</p>
			    </li>
			</ul>
		</div>
   </div>
</template>

<script>    
    import prettyJSON from "../utilities/prettyJson.js";
    import Stars from "./stars.vue";

    export default {
    	name: 'review-box',
        components: {
			Stars
        },
        props: [
			'cmts',
			'objid',
			'obj',
			'auth',
        ],
        data() {
            return {
        		comments: [],
        		title: '',
        		comment: '',
        		question: '',
        		rating: 4,
        		uploaded: false,
        		error: false,
        		errors: '',
        		asked: 0,
        	}
        },
        methods: {
        	closeComment() {
        		this.uploaded = false;
        		this.error = false;
        		this.title = '';
        		this.comment = '';
        		this.question = '';
        	},
        	closeComment() {
        		if (this.uploaded) {
        			location.reload();
        		}
        	},
   			submitComment() {
				let formData = new FormData();
				var self = this;
				this.uploaded = false; 
				this.error = false;

	            formData.set('id', this.objid);
	            formData.set('object', this.obj);

	            if (this.comment) {
	            	formData.set('title', this.title);
	            	formData.set('comment', this.comment);
	            	formData.set('rating', this.rating);
	            } else {
	            	formData.set('question', this.comment);
	            }

				//console.log ("form data for comment good to go: " + this.objid);

	            axios.post( '/comments/add/Trail', formData).then(response => {
	            	self.asked++;
                    self.uploaded = true; 
                    console.log(response.data);
                    if (response.data.message.cmt) {
                   		 this.comments.unshift(response.data.message.cmt);
                	}
                    
                })
		        .catch(error => {
		        	self.error = true;
                    if (error.response !== null) {
                        if (error.response.data){
                            self.errors = error.response.data.errors;
                            console.log("Comment upload failure: " + JSON.stringify(error.response.data)); 
                        }
                    } else {
                        console.log("ERROR:" + error); 
                    }
                });
			},
  		},
		mounted() {
			if (this.cmts) {
		 		this.comments = this.cmts.reverse();
		 	}
		 	//console.log(this.cmts);
		}
    }    
</script>>
