<template>
  <div>

    <form-wizard @on-complete="onComplete" ref="wizard" color="#666">
        <h4 slot="title" class="m-t-30">ADD A TRAIL</h4>
         <p v-if="errmsg"> {{ errmsg}} </p>
            <tab-content title="Location" icon="fa fa-map-marker-alt" :before-change="() => validate('step-location')">
                <step-location ref="step-location"  
                		:auth="auth"
                        @on-validate="onStepValidate" >                        
                </step-location>
            </tab-content>
            <tab-content title="Details" icon="fa fa-mountain" :before-change="() => validate('step-about')">
                <step-about ref="step-about"  
                        @on-validate="onStepValidate"
                        :finalModel="finalModel">                        
                </step-about>
            </tab-content> 
            <tab-content title="GPS" icon="fa fa-route" :before-change="() => validate('step-gps')">
                <step-gps ref="step-gps"  
                		:auth="auth"
                        @on-validate="onStepValidate"
                        :finalModel="finalModel">                        
                </step-gps>
            </tab-content> 

            <tab-content title="Photos" icon="fa fa-camera">
                <step-photo v-if="!done" ref="step-photo"  
                        @on-validate="onStepValidate"
                        :finalModel="finalModel" 
                        :id="id"
                        :auth="auth">                        
                </step-photo>
                <div v-if="done" class="text-center">
			    	<h3 class="text-center mt-3">We are done, thanks!</h3>
			    	<p> Visit your <a :href="'/trails/' + id"><u>trail</u></a> to see it!</p>

			    	<p> It isn't approved yet, but we'll review it before approving for display!</p>
			    </div>
            </tab-content>
            <!-- <tab-content title="Tags" icon="fa fa-tags">
				Enter some tags!
            </tab-content> -->
            <button class="btn btn-success m-l-50" slot="prev" v-show="!isLastStep()">Back</button>
            <button class="btn btn-success m-5-50" slot="next">Next</button>
            <button class="btn btn-success" slot="finish" v-show="isLastStep() && !done">Finish</button>                    
    </form-wizard>


  </div>
</template>

<script>    
    import 'vue-form-wizard/dist/vue-form-wizard.min.css';
    import StepLocation from './trail/stepLocation.vue';
    import StepGps from './trail/StepGps.vue';
    import StepAbout from './trail/StepAbout.vue';
    import StepPhoto from './trail/StepPhoto.vue';
    import prettyJSON from "../utilities/prettyJson.js";

    export default {
        name: 'trail-add-wizard',
 		props: {
             auth: {
			    default: false,
			    type: Boolean
			 }
        },
        components: {
            StepLocation,
            StepGps,
            StepAbout,
            StepPhoto,
        },
        data() {
            return {
                finalModel: {},
                id: '',
                done: false,
                errmsg: '',
            };
        },
        computed: {
            prettyJSON() {
                return prettyJSON(this.finalModel);
            }
        },
        methods: {
            onComplete() {
			    this.done = true;
			    //console.log(JSON.stringify(this.finalModel));
		    },
		    forceClearError() {
		      	this.$refs.wizard.tabs[this.activeTabIndex].validationError = null;
		    },
		    validate(ref) {
		      	return this.$refs[ref].validate(); // send to child component (step) validation
		    },
		    onStepValidate(validated, model) { // validated, child returns bool and model
		      if (validated) {
		          this.finalModel = { ...this.finalModel, ...model };

			      if (typeof model.id !== 'undefined' && model.id > 0) {
					    this.id = model.id;
				  }
		      } else if (model.errmsg) {
		      	  this.errmsg = model.errmsg;
		      }
		      return true;
		    },
		    isLastStep(){
			    if(this.$refs.wizard) {
			     	return this.$refs.wizard.isLastStep
			    }
			    return false
			}
        },
        mounted() {
            Event.listen('skip',  () => {
            	this.$refs.wizard.nextTab();
            });

        }
    }    
</script>
<style>
	.vue-form-wizard.md .wizard-icon-circle.checked {
       background-color: #666 !important;
	}
</style>
