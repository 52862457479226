var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "l-map",
        {
          ref: "myMap",
          staticStyle: { "z-index": "1000000" },
          style: {
            height: _vm.mapHeight,
            position: _vm.mapPosition,
            top: _vm.mapTop
          },
          attrs: {
            zoom: _vm.zoom,
            bounds: _vm.bounds,
            options: _vm.mapOptions
          },
          on: {
            "update:center": _vm.centerUpdate,
            "update:zoom": _vm.zoomUpdate
          }
        },
        [
          _c("l-tile-layer", {
            attrs: { url: _vm.url, attribution: _vm.attribution }
          }),
          _vm._v(" "),
          _c(
            "l-control",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expandMap,
                  expression: "expandMap"
                }
              ],
              attrs: { position: "bottomleft" }
            },
            [
              _c(
                "div",
                { staticClass: "custom-control", on: { click: _vm.expand } },
                [
                  _c("img", { attrs: { src: "/images/expand2.png" } }),
                  _vm._v("EXPAND MAP\n        ")
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "l-control",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shrinkMap,
                  expression: "shrinkMap"
                }
              ],
              attrs: { position: "bottomleft" }
            },
            [
              _c(
                "div",
                { staticClass: "custom-control", on: { click: _vm.shrink } },
                [
                  _c("img", { attrs: { src: "/images/expand2.png" } }),
                  _vm._v("SHRINK MAP\n        ")
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "l-control",
            {
              directives: [
                { name: "show", rawName: "v-show", value: 1, expression: "1" }
              ],
              ref: "filter",
              attrs: { position: "bottomright" }
            },
            [
              _vm._v("\n      \tChoose activity:\n\t\t"),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.activity,
                      expression: "activity"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "filter" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.activity = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.filter()
                      }
                    ]
                  }
                },
                _vm._l(_vm.activities, function(value, key) {
                  return _c("option", { domProps: { value: key } }, [
                    _vm._v(_vm._s(value) + "\n      \t\t")
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-marker-cluster",
            { attrs: { options: _vm.clusterOptions } },
            _vm._l(_vm.filtered, function(point) {
              return _c(
                "l-marker",
                {
                  key: point[2],
                  attrs: { "lat-lng": _vm.toLatLng(point[0], point[1]) }
                },
                [
                  _c(
                    "l-tooltip",
                    {
                      attrs: {
                        options: { permanent: false, interactive: true }
                      }
                    },
                    [
                      _c("div", [
                        _c("p", [
                          _vm._v("\n\t            " + _vm._s(point[3])),
                          _c("br"),
                          _vm._v(" "),
                          point[4]
                            ? _c("img", {
                                staticStyle: { width: "175px" },
                                attrs: {
                                  src: "/storage/content/trails/" + point[4]
                                }
                              })
                            : _vm._e(),
                          _c("br"),
                          _vm._v(
                            "\n \t\t\t\t\t< Click again for trail link >\n\t            "
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("l-popup", [
                    _c("div", [
                      _c("span", { staticClass: "text-center uppercase" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/trails/" + point[2],
                              target: "_blank"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(point[3].substring(0, 30).toUpperCase())
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/trails/" + point[2],
                              target: "_blank"
                            }
                          },
                          [
                            point[4]
                              ? _c("img", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    src: "/storage/content/trails/" + point[4]
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "text-center" }, [
                        _c("strong", [_vm._v("Click image to go to trail")])
                      ]),
                      _vm._v(" "),
                      _c("p")
                    ])
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }