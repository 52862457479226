var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "videoModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "videoModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { attrs: { id: "videoUp" } }, [
                  !_vm.uploaded
                    ? _c(
                        "h4",
                        { staticClass: "text-center m-t-20 m-b-30 m-r-30" },
                        [_vm._v(_vm._s(_vm.msg))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.auth && !_vm.uploaded
                    ? _c("div", { staticClass: "text-center m-l-20 m-t-20" }, [
                        _c("p", { staticClass: "m-l-20 text-center" }, [
                          _vm._v(
                            "Please add Youtube share link eg https://youtu.be/FGaBkdiyzYs"
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.video,
                              expression: "video"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { id: "video", type: "text" },
                          domProps: { value: _vm.video },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.video = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-secondary m-t-10",
                            on: { click: _vm.upload }
                          },
                          [_vm._v("Save Video Link!")]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploaded
                    ? _c("div", [
                        _c("p", { staticClass: "m-t-20" }, [
                          _vm._v(
                            " Thanks, your video was added, and will be reviewed. Please click close now and refresh page to review. "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.auth
                    ? _c("div", [
                        _c("p", { staticClass: "m-t-20" }, [
                          _vm._v(
                            " You must be signed in to add a photo, please close and login. "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors
                    ? _c("div", { staticClass: "text-center m-l-20 m-t-10" }, [
                        _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t            Failed, please fix or report error: " +
                              _vm._s(_vm.errors) +
                              "\n\t\t\t\t\t    \t"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary m-t-10",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.closeVideo }
                    },
                    [_vm._v("Close")]
                  )
                ])
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal-header",
        staticStyle: { "background-color": "#000", color: "orange" }
      },
      [
        _c(
          "h5",
          { staticClass: "modal-title", attrs: { id: "videoModalLabel" } },
          [_vm._v("Add Youtube Video")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close"
            }
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }