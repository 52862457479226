<template>
  <div>
    <l-map ref="myMap"
      :zoom="zoom"
      :bounds="bounds" 
      :options="mapOptions"
      :style="{ height: mapHeight, position: mapPosition, top: mapTop}"
      style="z-index:1000000"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-control :position="'bottomleft'" v-show="expandMap">
        <div @click="expand" class="custom-control">
           <img src="/images/expand2.png">EXPAND MAP
        </div>
      </l-control>
      <l-control :position="'bottomleft'" v-show="shrinkMap">
        <div @click="shrink" class="custom-control">
           <img src="/images/expand2.png">SHRINK MAP
        </div>
      </l-control>
      <l-control :position="'bottomright'" v-show="1" ref="filter">
      	Choose activity:
		<select name="filter" @change="filter()" class="form-control" v-model="activity">
			<option
        		v-for="(value, key) in activities" :value="key">{{ value }}
      		</option>
		</select>
      </l-control>
      <v-marker-cluster :options="clusterOptions">
	      <l-marker v-for="point in filtered" :key="point[2]" :lat-lng="toLatLng(point[0], point[1])">
	        <l-tooltip :options="{ permanent: false, interactive: true }"><div>
	            <p>
	            {{ point[3] }}<br/>
 				<img v-if="point[4]" :src="'/storage/content/trails/' + point[4]"  style="width:175px;"><br/>
 					< Click again for trail link >
	            </p>
	          </div>
	        </l-tooltip>
	        <l-popup>
	            <div>
	               <span class="text-center uppercase">
	                   <a :href="'/trails/' + point[2]" target="_blank">
	                   	  <strong>{{ point[3].substring(0,30).toUpperCase() }}</strong>
	                   </a>
	               </span>
	                <br/>
	             	<p>
	                	<a :href="'/trails/' + point[2]" target="_blank"><img v-if="point[4]" :src="'/storage/content/trails/' + point[4]"  style="width:200px;"></a> 
	                	<div class="text-center">
 					    	<strong>Click image to go to trail</strong>
 						</div>
	             	</p>
	            </div>
	        </l-popup>
	      </l-marker>
		</v-marker-cluster>
    </l-map>
  </div>
  		
</template>

<script>

import { LMap, LTileLayer, LMarker, LPopup, LControl, LTooltip } from "vue2-leaflet";
import { latLng, latLngBounds } from "leaflet";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import prettyJSON from "../../utilities/prettyJson.js";

export default {
  name: "show-map",
  props: {
     points: {
		default: null
	 },
	 zoomer: null,
	 lazy: {
	 	default: false
	 },
	 tbounds: Array 
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LControl,
    latLng,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  data() {
    return {
    	  allPoints: [],
    	  filtered: [],
    	  activity: "all",
	      zoom: 11,
	      url:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	      attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
	      currentZoom: 10,
	      clusterOptions: {
	      	  zoomToBoundsOnClick: true,
	      	  maxClusterRadius:120,
	      	  disableClusteringAtZoom: 10,
	      	  spiderfyOnMaxZoom: false
	  	  },
	      currentCenter: latLng(45.595437,-76.193053),
	      showParagraph: false,
	      mapOptions: {
	         zoomSnap: 0.5,
	         dragging: true,
	         scrollWheelZoom: false,
	         doubleClickZoom: false,
	      },
	      mapBounds: this.tbounds,
	      bounds: latLngBounds([
	        [51.554590, -116.697115],
	        [50.591665, -113.637422]
	      ]),
	      lazyLoad: this.lazy,
	      mapPosition: "absolute",
	      mapTop: "-20px",
	      mapHeight: "400px",
	      zIndex: 100001,
	      expandMap: true,
	      shrinkMap: false,
	      activities:  {
	      	all:'All',
  			hike:'Hike',
  			mtn_bike:'Mountain Bike',
  			ski_xc:'XC Ski',
  			climb:'Climb',
  			ski_bc:'Back Cntry Ski',
  			snowshoe:'Snowshoe',
  			sea_kayak:'Sea Kayak',
  			sup:'SUP',
  			skate:'skate',
  			road_bike:'Road Bike',
  			fat_bike:'Fat Bike',
  			scramble:'Scramble',
  			river_canoe:'Canoe'
  		 }
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    popMarker(lat, lng) {
       //
    },
    expand() {
    	this.mapHeight = "450px";
    	var mapi = this.$refs.myMap.mapObject;
    	mapi.setZoom(9);
    	this.expandMap = false;
    	this.shrinkMap = true;
    },
    shrink() {
    	this.mapHeight = "350px";
    	var mapi = this.$refs.myMap.mapObject;
    	mapi.setZoom(9);
    	this.expandMap = true;
    	this.shrinkMap = false;
    },
    filter() {
    	var selected = this.activity.replace(/_/g, "-"); // js object - error
    	if (selected == 'all') {
    		this.filtered = this.allPoints;
    	} else {
	    	this.filtered = [];
	        for (let i = 0; i < this.allPoints.length; i++) {
	        	if (this.allPoints[i][5].includes(selected)) {
	        	   this.filtered.push(this.allPoints[i]);
	        	}
	        }
    	}
    },
    getPoints() {
		var self = this;
  		axios.post("/api/getPoints", { bounds : this.mapBounds })
            .then(response => {
            	self.allPoints =  response.data;
            	if (self.activity !== 'all') { // active filter
            		self.filter();
            	} else {
            		self.filtered = self.allPoints;
            	}
            })
            .catch(error => {
                if (error.response !== null) {
                    if (error.response.data){
                        this.errors_ = error.response.data.errors;
                        console.log("call fail: " + JSON.stringify(error.response.data)); 
                    }
                } else {
                    console.log("ERROR:" + error); 
                }
            });
    },
    toLatLng(lat, lng) {
       return latLng(lat, lng);
    }
  },
  computed: {
  	LL(lat, lng) {
  	   return L.latLng(lat, lng);
  	}
  },
  mounted () {
  	var self = this;
  	if (this.points) {
  		this.allPoints = this.filtered = JSON.parse(this.points); // else get async in bounds
  	}

  	this.zoom = 11;
  	if (this.zoomer) {
  		this.zoom = this.zoomer;
  	}

  	// set bounds according to input
  	var southWest = new L.LatLng(self.mapBounds[2], self.mapBounds[1]);
    var northEast = new L.LatLng(self.mapBounds[0], self.mapBounds[3]);
    this.bounds   = new L.LatLngBounds(southWest, northEast);

    if (this.lazyLoad) {
  		this.getPoints();
  	}

	this.$nextTick(() => {
    	self.$refs.myMap.mapObject.on('moveend', function(e) {
	    	let lBounds = self.$refs.myMap.mapObject.getBounds();
	    	console.log(lBounds);
	    	self.mapBounds[0] = lBounds.getNorthWest().lat; 
	    	self.mapBounds[1] = lBounds.getSouthWest().lng; 
	    	self.mapBounds[2] = lBounds.getSouthWest().lat; 
	    	self.mapBounds[3] = lBounds.getSouthEast().lng; 

	    	self.getPoints();
      	})
  	});

  }
}
</script>
<style>

@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.custom-control {
  background: #fff;
  padding: 0 0.5em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
  float: right;
}
.marker-cluster span {
	font-weight: bolder !important;
}
.leaflet-shadow-pane {
	display: none !important;
}

</style>