<template>
    
   <div>
   		<div v-if="title">
    		<p class="mb-2 text-center">{{ title }}</p>
   		</div>
		<div v-if="fetched && title.startsWith('We are shuffling')" class="mb-2 btn-grp text-center">
			<button v-if="zoom > -2" class="btn btn-sm btn-success text-white font-weight-bold" @click="zoomIn">Closer In</button>
			<button v-if="zoom < 2" class="btn btn-sm btn-secondary text-white font-weight-bold" @click="zoomOut">Farther Out</button>
		</div>
		<!-- show nearby trails -->
		<div v-if="fetched">
			<div style="text-align:center">
			    <photo-box 
			    	:photos="images" 
			    	:hgt='100' 
			    	:what="'nearby'" 
		      	>
				</photo-box>

			</div>
			<div id="download-msg" v-if="errors" class="ml-3">
	 			<span style="color:red;">{{ errors }}</span>
	 		</div>
		</div>

   </div> 
</template>

<script>
    import prettyJSON from "../utilities/prettyJson.js";
    import PhotoBox from './photoBox.vue';

    export default {
        name: 'nearby-trails',
        components: {
			PhotoBox
        },
        props: {
        	tid : {
				default: null,
			},
        },
        data() {
            return {
                fetched: false,
			    errors: '',
			    success: '',
			    images: [],
			    zoom: 0,
			    title: '',
			    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        methods: {
		    getNearbyTrails() {
				this.fetched = false; 
				let formData = new FormData();
				this.errors = '';

	            formData.set('tid', this.tid);
	            formData.set('zoom', this.zoom);

		        axios.post('/trails/get-nearby-trails', formData,
		            ).then(response => {
		            	this.images = response.data.images;
		            	this.title = response.data.title;
		            	this.fetched = true;
		            	console.log(this.title);
		            	return true;
	                })
			        .catch(error => {
			        	this.fetched = false;

	                    if (error.response !== null) {
	                        if (error.response.data){
	                            this.errors = error.response.data.errors;
	                        }
	                    } else {
	                        this.errors = ' unknown fetch error, please contact us';
	                    }
	                });


	            return false;
		    },
			zoomIn() {
	    		this.zoom = this.zoom - 1;
	    		if (this.zoom < -2) {
	    			this.zoom = -2;
	    		}
	    		this.title = '';
	    		this.fetched = false;
	    		this.getNearbyTrails();
	        },
	        zoomOut() {
	    		this.zoom = this.zoom + 1;
	    		if (this.zoom > 2) {
	    			this.zoom = 2;
	    		}
	    		this.title = '';
	    		this.fetched = false;
	    		this.getNearbyTrails();
	        },
	    },
        mounted() {
    		this.title = '';
    		this.fetched = false;
    		this.getNearbyTrails();
        },
        destroyed() {
		    //console.log('Component has been destroyed!');
		}
    }

</script>

<style>
</style>