var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-inline-block" }, [
    _c(
      "ul",
      { staticClass: "m-0 p-0" },
      _vm._l(_vm.stars, function(star) {
        return _c("li", { staticClass: "star" }, [
          _c("i", {
            staticClass: "flaticon-star-1",
            style: { color: _vm.color, "font-size": _vm.size }
          })
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }