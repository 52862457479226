<template>
    
    <div>
        <div class="row justify-content-md-center m-t-20">
            <h5><b>Build Buzz by getting updates from other users and destinations</b></h5>
        </div>

        <div v-if="errors" style="color:red;">
        	Please fix missing field or errors with the following {{ this.errors }}
        </div>

        <div id="trail-details">
            <div class="container" style="padding: 0 50px 20px 0">

            	<div class="row m-t-20">
                    <div class="col-md-12">
                    	<input type="checkbox" id="newsletter" v-model="model.newsletter"><span class="form-text"> Receive Our Newsletter (future)? </span>
					</div>
				</div>

				<div class="row m-t-20">
                    <div class="col-md-12">
                    	<input type="checkbox" id="contactme" v-model="model.contactme"> 
                    	<span class="form-text"> Let other users contact you (email addresses never shared)</span>
					</div>
				</div>

				<div class="row m-t-20">
                    <div class="col-md-6">
						<label for="gender_contactme">Limit Who can contact you by gender?</label>
						<select id="gender_contactme" class="form-control" v-model="model.gender_contactme">
						  <option v-for="g in genders" v-bind:value="g">
						     {{ g }}
						  </option>
						</select>
					</div>
				</div>

				<div class="row m-t-20">
                    <div class="col-md-6">
						<label for="age_contactme">Limit age range for contact?</label>
						<select id="age_contactme" class="form-control" v-model="model.age_contactme">
						  <option v-for="a in ageRange" v-bind:value="a">
						     {{ a }}
						  </option>
						</select>
					</div>
				</div>

				<div class="row m-t-20">
                    <div class="col-md-6">
		            	<input type="checkbox" id="followme" v-model="model.followme"><span class="form-text"> Let other users follow you (see what you add or like)</span>
					</div>
				</div>

				<div class="row m-t-20">
                    <div class="col-md-6">
						<label for="gender_followme">Limit Who can follow you by gender?</label>
						<select id="gender_followme" class="form-control" v-model="model.gender_followme">
						  <option v-for="g in genders" v-bind:value="g" 
						     :selected="model.gender_followme == g ? true : false">
						     {{ g }}
						  </option>
						</select>
					</div>
				</div>

				<div class="row m-t-20">
                    <div class="col-md-6">
						<label for="age_followme">Limit age range for following you?</label>
						<select id="age_followme" class="form-control" v-model="model.age_followme">
						  <option v-for="a in ageRange" v-bind:value="a" >
						     {{ a }}
						  </option>
						</select>
					</div>
				</div>

				<div v-if="uploaded"class="row m-t-20">
                    <div class="col-md-6">
                    	 <h3>Updated!</h3>
                    	 <p>( now click finish ==> below )</p>
					</div>
				</div>

				<div v-if="!uploaded" class="row m-t-20">
                    <div class="col-md-6">
                    	 <button class="btn btn-secondary m-t-10" @click="upload">Buzz Your Profile (SUBMIT to Profile Now)</button>
					</div>
				</div>

            </div>   
            
            <div v-if="errors" style="color:red;">
        		Please fix missing field or errors with the following {{ this.errors }}
        	</div>  
        </div>
    
    </div>  

</template>

<script>
   //import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';

    export default {
        name: 'step-buzz',
        components: {
	       // VTooltip,
	       // VPopover,
	       // VClosePopover,
	    },
        props: {
			uid: {
				default: null,
			},
			editModel: {
				default: null,
			},
        },
        data() {
            return {
               model: {
                    newsletter: false,
                    contactme: false,
                    age_contactme: 'all',
                    gender_contactme: 'all',
                    followme: '',
                    age_followme: 'all',
                    gender_followme: 'all',
                },
                id: '',
                uploaded: false,
                genders: ['all', 'male', 'female', 'none'],
                ageRange: ['all', '<25', '<35', '<50', '35-55', '30+'],
                errors: '',
            }
        },
        methods: {
            validate() {
	              var valid = true;
	              this.$emit('on-validate', valid, this.model); 
	              return valid;
            },
            validNum ( num ) {
            	var reg = /^\d+$/;
            	return reg.test( num );
        	},
        	upload() {
				let formData = new FormData();
				this.errors = '';
				var self = this;
				this.uploaded = false; // server upload - start
	            
	            formData.set('buzz', JSON.stringify(this.model));
	            formData.set('id', self.id);

	            axios.post( '/users/add-buzz', formData,
	            ).then(response => {
                    self.uploaded = true; 
                })
		        .catch(error => {
                    if (error.response !== null) {
                        if (error.response.data) {
                            self.errors = error.response.data.errors;
                            console.log("Buzz upload failure: " + JSON.stringify(error.response.data)); 
                        }
                    } else {
                    	this.errors = "server error, please contact us, it is not you!"
                        console.log("Buzz ERROR:" + error); 
                    }
                });

                return self.uploaded;
			},
		},
        mounted() {
        	this.id = this.uid;

        	if (this.editModel != null) {
        		    this.model.newsletter = this.editModel.newsletter;
                    this.model.contactme = this.editModel.contactme;
                    this.model.age_contactme = this.editModel.age_contactme;
                    this.model.gender_contactme = this.editModel.gender_contactme;
                    this.model.followme = this.editModel.followme;
                    this.model.age_followme = this.editModel.age_followme;
                    this.model.gender_followme = this.editModel.gender_followme;
        	}
        	
        }
    }

</script>